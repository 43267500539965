import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Modal from "react-modal";
import { BASE_URL } from "./Base_url";
import useToken from "./TokenContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import { set } from "date-fns";



const screenWidth = window.innerWidth;
const numColumns = 3;
const imageSize = (screenWidth / numColumns)*0.75;

export default function PersonalProfile({setIsAuthenticated}) {
  const navigate = useNavigate();
  const { token, refreshToken, profile, pomos_this_week, do_today, classes, students_list, updateStudents  } = useToken();
  const [display, setDisplay] = useState("wotd");
  const [wotd, setWOTD] = useState(null);
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [image, setImage] = useState(null);
  const [ispublic, setIsPublic] = useState(true);
  const [modalView, setModalView] = useState("addWOTD");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedWotd, setSelectedWotd] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [possibleStudents, setPossibleStudents] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [confirmStudentDelete, setConfirmStudentDelete] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  Modal.setAppElement('#root');

  const pickImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImage({
        uri: reader.result,
        base64: reader.result.split(',')[1],
        fileName: file.name,
      });
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };
    


  const upload_image = async () => {
    if (!image?.base64) {
        alert('No image selected');
        return;
      }
    
      const data = {
        image: image.base64, // Use the Base64 data directly
        filename: image.fileName,
      };

    let jsonData;
    try {
      jsonData = JSON.stringify(data);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    const response = await fetch(`${BASE_URL}/api/wotd/image`, {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      try {
        await refreshToken(); 
        
      } catch (error) {
        
          // Handle token refresh failure
          throw error;
      }
    }

  

    const responseBody = await response.json();
 

    if (response.status === 200) {
      return responseBody;
    } else {
      alert("There was an error uploading the image.");
      return null;
    }
  };

  const submitData = async () => {
    const image_url = await upload_image();

    let wotdObject = {
      username: profile[0].username,
      wotd_id: "ha",
      post_link: image_url,
      description: description,
      tags: tags.split(" ").map((tag) => tag.trim()),
      likes: 0,
      public: ispublic,
      creation_date: "ha",
    };

    let jsonData;
    try {
      jsonData = JSON.stringify(wotdObject);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    try {
      let response = await fetch(`${BASE_URL}/api/wotd`, {
        method: "POST",
        body: jsonData,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
   
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseBody = await response.json();
    
      setIsPublic(true);
      setDescription("");
      setTags("");
      setImage(null);
      setVisible(false);
      setWOTD([wotdObject, ...wotd]);
      return responseBody;
    } catch (error) {
      console.error(error);
      alert("There was an error uploading the wotd.");
      return null;
    }
  };

  const logOut = async () => {
    try {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      setIsAuthenticated(false);

    } catch (error) {
      // Handle token refresh failure
      throw error;
    }
  }; 

  const deleteAccount = async () => {
    try {
      let response = await fetch(`${BASE_URL}/api/user/delete?username=${profile[0].username}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
   
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      logOut();
    } catch (error) {
      console.error(error);
      alert("There was an error deleting your account.");
      return null;
    }
  }


  const addStudent = async (student_username, student_image) => {
    updateStudents({"username": student_username, "image": student_image}, false);
    setSearchQuery("");
    setPossibleStudents([]);
    setIsSearching(false);
    
    fetch(`${BASE_URL}/api/user/add_student?username=${profile[0].username}&student_username=${student_username}&student_image=${student_image}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });




  };

  const removeStudent = async (studentUsername) => {
    try {
      await fetch(`${BASE_URL}/api/user/remove_student?username=${profile[0].username}&student_username=${studentUsername}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update local state by filtering out the removed student
      updateStudents({"username": studentUsername}, true);
      setConfirmStudentDelete(false);
      setStudentToDelete(null);
    } catch (error) {
      console.error("Error removing student:", error);
    }
  };



  



  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(`${BASE_URL}/api/wotd?username=${profile[0].username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((fetchedData) => setWOTD(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);

 

  

  const handleWotdClick = (wot, index) => {
    setSelectedWotd(wot);
    setSelectedIndex(index);
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      setSelectedWotd(wotd[selectedIndex - 1]);
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleNext = () => {
    if (selectedIndex < wotd.length - 1) {
      setSelectedWotd(wotd[selectedIndex + 1]);
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const searchStudents = async (query) => {
    if (!query) {
        setPossibleStudents([]);
        return;
    }
    
    try {
        const response = await fetch(`${BASE_URL}/api/user/possible_students?username=${query}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        setPossibleStudents(Array.isArray(data) ? data : []);
    } catch (error) {
        console.error("Error searching students:", error);
        setPossibleStudents([]);
    }
  };

  

  return (
    <div style={{ flexGrow: 1, backgroundColor: "#2b2b2b", overflow: "hidden" }}>
      <Modal isOpen={visible} onRequestClose={() => {setVisible(false); setModalView("addWOTD"); setConfirmDelete(false)}} style = {{overlay: {
      backgroundColor: 'transparent'
    },
    content: {
      backgroundColor: 'transparent',
      border: 'none',
      width: '10%',
      height: '10%',
      alignSelf: 'center',
      
    }}}>
        {modalView === "addWOTD" ? (
        <>
        <div className="add_wotd">
        
      <div  className="image_box" onClick={() => document.getElementById('fileInput').click()}>
        {image ? (
            <img src={image.uri} alt="Picked" style={{ width: "100%", height: "100%", borderRadius: 10 }} />
        ) : (
            <p className="text">Pick Image</p>
        )}
        <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={pickImage}
            accept="image/*"
        />
        </div>

       
        
        <input
            id="description"
            type="text"
            maxLength="40"
            placeholder="Description"
            value={description}
            onChange={(e) => {setDescription(e.target.value)}}
            className="description"
        />
       

        
        
        <input
            id="tags"
            type="text"
            placeholder="Tags"
            
            maxLength="20"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="description"
        />
      

        <div className="iwanttoslammyheadagainstthewall">
          <button
            className={ispublic ? "sign_in_butt_public" : "sign_in_butt_private"}
            onClick={() => setIsPublic(!ispublic)}
          >
            <p className="text">{ispublic ? "Public" : "Private"}</p>
          </button>
          <button
            className="sign_in_butt"
            onClick={() => {
              submitData();
              setVisible(false);
            }}
          >
            <p className="text">Submit</p>
          </button>
        </div>
        </div>
        </> ):(
          <>
          <div className="sign_out"> 
          {!confirmDelete ? (
          <button className="sign_out_butt" onClick={() => {logOut()}}>
            <p >Log Out</p>
          </button>) : (
            <p className="text" >Confirm Deletion</p>
          )}
          
          <button className="delete_butt" onClick={() => {!confirmDelete? (setConfirmDelete(true)):(deleteAccount())}}>
            <p >Delete Account</p>
          </button>
          
          </div>
          </>
        )
        }
      </Modal>
      <div className="container2">
        {profile && (
          <div className = "iwishwenevermet">
            <img className="profile_pic" src={profile[0].profile_picture} alt="Profile" />
           
            <div className="drain">
                
              <p className="username">{profile[0].username}</p>
                <p className="text">Pomodoros: {pomos_this_week}</p>
                
            </div>
            <div className="pain">
                
            <button 
              className="sign_in_butt" 
              onClick={() => {setModalView("sigh_out"); setVisible(true)}}> 
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                size="2xl"
                style={{ color: "ivory" }}
              />
            </button>
                
            </div>
          </div>
        )}
        <div className="aha">
          <div className="current_tasks">
            <p className="current_task_display">Todays Tasks</p>
            {do_today && do_today.length > 0 ? (
              do_today.map((task, index) => (
                <p className="actual_current_tasks"key={index}>
                  {task.title}
                </p>
              ))
            ) : (
              <p className="actual_current_tasks">None</p>
            )} 
          </div>
         
            <div className="iwanttoslammyheadagainstthewall">
              <button
                className={`sign_in_butt ${display === "wotd" ? "" : "opacity-50"}`}
                onClick={() => setDisplay("wotd")}
              >
                <p className="text">wins</p>
              </button>
              <button
                className={`sign_in_butt ${display === "classes" ? "" : "opacity-50"}`}
                onClick={() => setDisplay("classes")}
              >
                <p className="text">classes</p>
              </button>
              {profile && profile.length > 0 && profile[0].role === "teacher" && (
                <button
                  className={`sign_in_butt ${display === "students" ? "" : "opacity-50"}`}
                  onClick={() => setDisplay("students")}
                >
                  <p className="text">students</p>
                </button>
              )}
            </div>
            {display === "wotd" ? (
              <>
                <div className="waste">
                  <div className="wotd-item">
                    <button 
                      className="add-wotd-button"
                      onClick={() => {setVisible(true); setModalView("addWOTD"); }}
                    >
                      <span className="add-wotd-icon">+</span>
                      <p className="add-wotd-text">add post</p>
                    </button>
                  </div>
                  {wotd &&
                    wotd.map((wot, index) => (
                      <div 
                        key={index} 
                        className="wotd-item"
                        onClick={() => handleWotdClick(wot, index)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          style={{ 
                            width: "100%", 
                            height: "100%", 
                            borderRadius: 10,
                            objectFit: "cover"
                          }}
                          src={wot.post_link}
                          alt="WOTD"
                        />
                      </div>
                    ))}
                </div>

                {selectedWotd && (
                  <div className="wotd-modal" onClick={() => setSelectedWotd(null)}>
                    <div className="wotd-modal-content" onClick={e => e.stopPropagation()}>
                      <img 
                        className="wotd-modal-image"
                        src={selectedWotd.post_link} 
                        alt="WOTD Full Size" 
                      />
                      <div className="wotd-modal-details">
                        <div className="wotd-modal-description">
                          {selectedWotd.description}
                        </div>
                        <div className="wotd-modal-tags">
                          {selectedWotd.tags.map((tag, index) => (
                            <span key={index} className="tag">
                              #{tag.trim()}
                            </span>
                          ))}
                        </div>
                      </div>
                      {selectedIndex > 0 && (
                        <button 
                          className="modal-nav-button left"
                          onClick={handlePrevious}
                        >
                          <FontAwesomeIcon icon={faCircleChevronLeft} size="2x" />
                        </button>
                      )}
                      {selectedIndex < wotd.length - 1 && (
                        <button 
                          className="modal-nav-button right"
                          onClick={handleNext}
                        >
                          <FontAwesomeIcon icon={faCircleChevronRight} size="2x" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : display === "classes" ?(
              <div className="classes-container">
                <button
                  className="add-class-button"
                  onClick={() => navigate('/add-class')}
                >
                  <p className="text">+</p>
                  <p className="text">add class</p>
                </button>
                <div className="classes-grid">
                  {classes &&
                    classes.map((classItem, index) => (
                      <div key={classItem.class_id} className="class-item">
                        <button
                          className="class-card"
                          onClick={() => navigate('/add-class')}
                        >
                          <span className="class-icon">{classItem.icon}</span>
                          <p className="class-text">{classItem.class_name}</p>
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            ) : display === "students" ? (
              <div className="classes-container">
                {isSearching ? (
                  <>
                    <div>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                searchStudents(e.target.value);
                            }}
                            placeholder="student username..."
                            className="student-search"
                        />
                        <button onClick={() => setIsSearching(false)} className="cancel-button">Cancel</button>
                    </div>
                    <div className="classes-grid">
                        {Array.isArray(possibleStudents) && possibleStudents.map((student) => (
                            <div 
                                key={student.username} 
                                className="class-item"
                            >
                                <button 
                                    className="class-card"
                                    onClick={() => addStudent(student.username, student.image)}
                                >
                                    <img 
                                        src={student.image} 
                                        alt={student.username}
                                        style={{
                                            width: "70%",
                                            height: "70%",
                                            objectFit: "cover",
                                            borderRadius: 10,
                                            marginBottom: "10%"
                                        }} 
                                    />
                                    <p className="text">{student.username}</p>
                                </button>
                            </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <button 
                      className="add-class-button"
                      onClick={() => setIsSearching(true)}
                    >
                      <p className="text">+</p>
                      <p className="text">add student</p>
                    </button>
                   
                    <div className="classes-grid">
                      {students_list.map((student) => (
                        <div 
                          key={student.username} 
                          className="class-item"
                          
                        >
                          {confirmStudentDelete && studentToDelete === student.username ? (
                            <div className="confirm-delete-overlay">
                              <p className="text">Confirm removal?</p>
                              <div className="confirm-buttons">
                                <button onClick={() => removeStudent(student.username)}>Yes</button>
                                <button onClick={() => {
                                  setConfirmStudentDelete(false);
                                  setStudentToDelete(null);
                                }}>No</button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <button 
                                className="delete-student-button"
                                onClick={() => {
                                  setConfirmStudentDelete(true);
                                  setStudentToDelete(student.username);
                                }}
                              >
                                <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "ivory" }} />
                              </button>
                              <button className="class-card" onClick={() => navigate(`/student-view/${student.username}`)}>
                                <img 
                                  src={student.image} 
                                  alt={student.username} 
                                  style={{
                                    width: "70%",
                                    height: "70%",
                                    objectFit: "cover",
                                    borderRadius: 10,
                                    marginBottom: "10%"
                                  }}
                                />
                                <p className="text">{student.username}</p>
                              </button>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
        </div>
      </div>
    </div>
  );
}
