import React, { useState, useEffect, useContext, useRef } from "react";
import Select from 'react-select';
import useToken from "./TokenContext";
import { BASE_URL } from "./Base_url.js";
import { useAudio } from './AudiContext';
import { usePomodoro } from './PomodoroContext';
import "./App.css";

export default function Pomodoro() {
  const { 
    mainTimer, setMainTimer, 
    breakTimer, setBreakTimer, 
    isRunning, setIsRunning, 
    isBreak, setIsBreak, 
    pomostoday, canvasRef, 
    progress, minutes, seconds,
    classOptions,
    input_class_name,
    setInputClassName ,
    pomoLength, setPomoLength
  } = usePomodoro();

  const { playSound, audioRef } = useAudio();
  const {aaa, setAaa} = useState(0);

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#2b2b2b",
      borderColor: 'ivory',
      color: 'ivory',
      width: '200px',
      marginTop: '10px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#2b2b2b",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#404040" : "#2b2b2b",
      color: 'ivory',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'ivory',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'ivory',
    }),
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = centerX * 0.8;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the background circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = "#2b2b2b";
    ctx.fill();

    // Draw the countdown arc
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, radius, -Math.PI / 2, progress * 2 * Math.PI - Math.PI / 2);
    ctx.lineTo(centerX, centerY);
    ctx.fillStyle = isBreak ? "#F3B04C" : "#B95A34";
    ctx.fill();
  }, [progress, isBreak]);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "#2b2b2b", height: "100%" }}>
      <div style={{ fontSize: 25, color: 'ivory', fontWeight: 'bold', margin: '0' }}>Pomodoros: {pomostoday}</div>
      <Select
        className="pomodoro-select"
        placeholder="Class"
        value={input_class_name}
        onChange={(selectedOption) => setInputClassName(selectedOption)}
        options={classOptions}
        styles={{
          ...customSelectStyles,
          control: (base) => ({
            ...base,
            backgroundColor: "#2b2b2b",
            borderColor: 'ivory',
            color: 'ivory',
            width: '200px',
            marginTop: '10px',
            marginBottom: '0px',
            margin: '10px auto 0',
          }),
        }}
        isClearable={true}
        defaultValue={null}
      />
      <div style={{ 
        width: '60vh', 
        height: '60vh', 
        position: "relative",
        marginTop: '0px',
      }}>
        <canvas 
          ref={canvasRef} 
          width={500} 
          height={500} 
          style={{
            width: '100%',
            height: '100%'
          }}
        />
        <div style={{ 
          position: "absolute", 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center" 
        }}>
          <h1 style={{ 
            fontSize: 'calc(3vw + 20px)',
            color: 'ivory',
            fontWeight: 'bold',
            maxFontSize: '48px'
          }}>
            {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
          </h1>
        </div>
      </div>

      <div>
   
      <button
        style={{ fontSize: 25, color: 'ivory', fontWeight: 'bold', backgroundColor: "transparent", border: "none", cursor: "pointer", marginRight: 10 }}
        onClick={() => {
          if (isRunning) {
            localStorage.removeItem('startTime');
            localStorage.removeItem('isBreak');
            setIsRunning(false);
            if (!isBreak) {
              setMainTimer( pomoLength[0]  * 60);
            } else {
              setBreakTimer( pomoLength[1] * 60);
              setMainTimer( pomoLength[0] * 60);
              setIsBreak(false);
            }
          } else {
            localStorage.setItem('startTime', Date.now());
            localStorage.setItem('isBreak', isBreak);
            setIsRunning(true);
          }
        }}
      >
        {isRunning ? "Stop" : "Start"}
      </button>
      <button 
      style={{ fontSize: 25, color: 'ivory', fontWeight: 'bold', backgroundColor: "#F3B04C", border: "none", cursor: "pointer", borderRadius:10, marginLeft:10, marginBottom: 30 }}
      onClick ={() =>{
        if (pomoLength[0] === 25){
          setPomoLength([45,15])
          setMainTimer(45*60)
          setBreakTimer(15*60)
        }
        else{
          setPomoLength([25,5])
          setMainTimer(25*60)
          setBreakTimer(5*60)

        }

      }}
      >
         {pomoLength[0] == 25 ? 45 : 25}
      </button>

      </div>
      
    </div>
  );
}