import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Statistics from "./Statistics.js";
import React, { useState, useEffect, useRef } from "react";
import { Modal, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { BASE_URL } from "./Base_url.js";
import useToken from "./TokenContext.js";
import {
  faCircleChevronRight,
  faCircleChevronLeft,
  faCircleXmark,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format } from "date-fns";
import { useAudio } from "./AudiContext";

export default function StudentTaskView() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [display, setDisplay] = useState("kan-ban");
  const [taskList, setTasks] = useState([]);
  const { token, refreshToken, profile, updateDoToday, } = useToken();

  const [statisticsData, setStatisticsData] = useState({ days: null, weeks: null });
  const [classes, setClasses] = useState([]);


  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(`${BASE_URL}/api/class?username=${username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((fetchedData) => setClasses(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);

  const refreshStatistics = async () => {
    if (username && token) {
      try {
        const [daysResponse, weeksResponse] = await Promise.all([
          fetch(`${BASE_URL}/api/report/days?username=${username}`, {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${BASE_URL}/api/report/weeks?username=${username}`, {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
        ]);

        if (daysResponse.status === 401 || weeksResponse.status === 401) {
          await refreshToken();
          return await refreshStatistics();
        }

        const [daysData, weeksData] = await Promise.all([
          daysResponse.json(),
          weeksResponse.json()
        ]);

        setStatisticsData({ days: daysData, weeks: weeksData });
      } catch (error) {
        console.error("Error refreshing statistics:", error);
      }
    }
  };

  const handleTaskCompletion = async (taskId, status) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/student_task/${taskId}/status?username=${username}&status=${status}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        await refreshToken();
        return await handleTaskCompletion(taskId, status);
      }

      if (response.ok) {
        if (status === 'done') {
          await Promise.all([
            fetch(`${BASE_URL}/api/student_task/${taskId}/done?username=${username}&done=true`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }),
            fetch(`${BASE_URL}/api/student_task/${taskId}/done_date?username=${username}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
          ]);
        }
        
        await refreshStatistics();
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (token && username) {
        try {
          const tasksResponse = await fetch(`${BASE_URL}/api/task?username=${username}`, {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const tasksData = await tasksResponse.json();
          setTasks(tasksData);

          await refreshStatistics();
        } catch (error) {
          console.error("Error fetching initial data:", error);
        }
      }
    };

    fetchInitialData();
  }, [token, username]);

  const [calendar_view, setCalendarView] = useState("do_date");
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState("todo");
  const [title, setTitle] = useState("");
  const [input_do_date, setInputDoDate] = useState(new Date());
  const [input_due_date, setInputDueDate] = useState(new Date());
  const [input_class_name, setInputClassName] = useState(null);
  const { playSound } = useAudio();
  const [theDoDate, setTheDoDate] = useState(new Date());
  const [theDueDate, setTheDueDate] = useState(new Date());

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // STATISTICS LOGIC AND HANDLING

  const [stats_display, setStatsDisplay] = useState("days");
  const [daysData, setDaysData] = useState(null);
  const [weeksData, setWeeksData] = useState(null);

  // Update local state when statisticsData changes
  useEffect(() => {
    if (statisticsData.days) {
      setDaysData(statisticsData.days);
    }
    if (statisticsData.weeks) {
      setWeeksData(statisticsData.weeks);
    }
  }, [statisticsData]);

  const previousMonth = () => {
    setSelectedMonth((prev) => prev - 1);
  };
  const nextMonth = () => {
    setSelectedMonth((prev) => prev + 1);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleDragStart = (e, task) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(task));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, formattedDate) => {
    e.preventDefault();
    const taskData = JSON.parse(e.dataTransfer.getData("text/plain"));
    const updatedTasks = taskList.map((task) =>
      task.task_id === taskData.task_id
        ? { ...task, [calendar_view]: formattedDate }
        : task
    );
    setTasks(updatedTasks);
    if (calendar_view === "due_date") {
      changeDueDate(taskData.task_id, formattedDate);
    } else {
      const today = new Date();
      const today_formatted = format(today, "yyyy-MM-dd");
      if (formattedDate === today_formatted) {
        updateDoToday(taskData, false);
      } else if (taskData.do_date === today_formatted) {
        updateDoToday(taskData, true);
      }
      changeDoDate(taskData.task_id, formattedDate);
    }
  };

  const changeDoDate = async (task_id, do_date) => {
    const response = await fetch(
      `${BASE_URL}/api/student_task/do_date?username=${username}&task_id=${task_id}&do_date=${do_date}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }
  };

  const changeDueDate = async (task_id, due_date) => {
    const response = await fetch(
      `${BASE_URL}/api/student_task/due_date?username=${username}&task_id=${task_id}&due_date=${due_date}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }
  };

  const generateID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const renderCalendar = (type) => {
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
    const startDay = firstDayOfMonth === 0 ? 7 : firstDayOfMonth; // Adjust Sunday from 0 to 7
    const prevMonthDays = getDaysInMonth(selectedMonth - 1, selectedYear);
    const endDay = new Date(selectedYear, selectedMonth + 1, 0).getDay();

    const calendarRows = [];
    let calendarDays = [];

    // Render cells for days from the previous month
    for (let i = startDay - 1; i > 0; i--) {
      const prevMonthDate = new Date(
        selectedYear,
        selectedMonth - 1,
        prevMonthDays - i + 1
      );
      const formattedDate = format(prevMonthDate, "yyyy-MM-dd");
      calendarDays.push(
        <div
          key={`prev-month-${prevMonthDays - i + 1}`}
          className="day_not_month"
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, formattedDate)}
        >
          <div className="day_number">{prevMonthDays - i + 1}</div>
          {renderTasksForDate(formattedDate, type)}
        </div>
      );
    }

    // Render cells for each day of the current month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(selectedYear, selectedMonth, day);
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      calendarDays.push(
        <div
          key={`day-${day}`}
          className="day"
          id={`day-${day}`}
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, formattedDate)}
        >
          <div className="day_number">{day}</div>
          {renderTasksForDate(formattedDate, type)}
        </div>
      );

      // If we've reached the end of a week, start a new row
      if ((startDay + day - 1) % 7 === 0) {
        calendarRows.push(
          <div
            key={`row-${calendarRows.length}`}
            className="calendar_container"
          >
            {calendarDays}
          </div>
        );
        calendarDays = [];
      }
    }

    // Fill the remaining cells in the last row with days from the next month
    if (endDay !== 0) {
      const remainingDays = 7 - endDay;
      for (let i = 1; i <= remainingDays; i++) {
        const nextMonthDate = new Date(selectedYear, selectedMonth + 1, i);
        const formattedDate = format(nextMonthDate, "yyyy-MM-dd");
        calendarDays.push(
          <div
            key={`next-month-${i}`}
            className="day_not_month"
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e, formattedDate)}
          >
            <div className="day_number">{i}</div>
            {renderTasksForDate(formattedDate, type)}
          </div>
        );
      }
    }

    // Add the last row to the calendarRows array
    if (calendarDays.length > 0) {
      calendarRows.push(
        <div key={`row-${calendarRows.length}`} className="calendar_container">
          {calendarDays}
        </div>
      );
    }

    return calendarRows;
  };

  // Helper function to check if a string starts with an emoji
  const startsWithEmoji = (str) => {
    const emojiRegex = /^[\u{1F300}-\u{1F9FF}]|[\u{2600}-\u{26FF}]/u;
    return emojiRegex.test(str);
  };

  // Helper function to extract emoji from class name
  const getClassIcon = (className) => {
    if (!className || className === "other" || className === "string") return "";
    const emoji = className.split(" ")[0];
    return emoji;
  };

  // Helper function to render tasks for a specific date
  const renderTasksForDate = (date, type) => {
    return taskList
      .filter((task) => task[type] === date)
      .map((task) => (
        <div
          key={task.task_id}
          className={`calendar_task_container_${task.status}`}
          draggable="true"
          onDragStart={(e) => handleDragStart(e, task)}
        >
          {task.title} {getClassIcon(task.class_name)}
        </div>
      ));
  };

  const changeStatus = async (task_id, status) => {
    const response = await fetch(
      `${BASE_URL}/api/student_task/${task_id}/status?username=${username}&status=${status}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }

    if (response.ok) {
      // Find the task that was updated
      const task = taskList.find((task) => task.task_id === task_id);

      // Update the task's status
      const updatedTask = { ...task, status };

      if (status === "done") {
        updatedTask.done = true;
        changeDoneDate(task_id);
      }

      // Update the state
      setTasks((tasks) =>
        tasks.map((task) => (task.task_id === task_id ? updatedTask : task))
      );
    }
  };

  const deleteTask = async (task_id) => {
    const response = await fetch(
      `${BASE_URL}/api/student_task/${task_id}?username=${username}`,
      {
        method: "DELETE",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }

    if (response.ok) {
      setTasks((tasks) => tasks.filter((task) => task.task_id !== task_id));
    }
  };

  const onChangeDoDate = (date) => {
    const adjustedDate = new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    );
    const formattedDate = format(adjustedDate, "yyyy-MM-dd");
    setInputDoDate(formattedDate);
    setTheDoDate(date);
  };

  const onChangeDueDate = (date) => {
    const adjustedDate = new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    );
    const formattedDate = format(adjustedDate, "yyyy-MM-dd");
    setInputDueDate(formattedDate);
    setTheDueDate(date);
  };

  const isDateFormattedCorrectly = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  const changeDoneDate = async (task_id) => {
    const response = await fetch(
      `${BASE_URL}/api/student_task/${task_id}/done_date?username=${username}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const upload_task = async () => {
    // Create a new FormData instance

    if (title === "" || title === null) {
      return;
    }

    let lets_do_date = input_do_date;
    let lets_due_date = input_due_date;

    if (!isDateFormattedCorrectly(input_do_date)) {
      const adjustedDate = new Date(
        input_do_date.setMinutes(
          input_do_date.getMinutes() - input_do_date.getTimezoneOffset()
        )
      );
      const formattedDate = format(adjustedDate, "yyyy-MM-dd");
      setInputDoDate(formattedDate);
      lets_do_date = formattedDate;
    }
    if (!isDateFormattedCorrectly(input_due_date)) {

      const adjustedDate = new Date(
        input_due_date.setMinutes(
          input_due_date.getMinutes() - input_due_date.getTimezoneOffset()
        )
      );
      const formattedDate = format(adjustedDate, "yyyy-MM-dd");
      setInputDueDate(formattedDate);
      lets_due_date = formattedDate;
    }

    let stat = "todo";

    if (status.value != null) {
      stat = status.value;
    }

    // Create the task object using the student's username
    const taskObject = {
      username: username, // Use the student's username
      task_id: generateID(),
      title: title,
      class_name: input_class_name?.label || "other", // Default to "other" without emoji
      due_date: lets_due_date,
      do_date: lets_do_date,
      status: stat,
      done_date: "",
      done: false,
    };

   

    let jsonData;
    try {
      jsonData = JSON.stringify(taskObject);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/student_task`, {
        method: "POST",
        body: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

 

      if (response.status === 401) {
        // Unauthorized
        await refreshToken();
        // Retry the request
      }

      const responseBody = await response.json();
      if (response.status === 200) {
        // Success
        setTasks((tasks) => [responseBody, ...tasks]); // Extract data from the response
      }
    } catch (error) {
      console.error(error);
    }
    setVisible(false);

    setTitle("");
    setStatus({ value: "todo", label: "Todo" });
    setInputClassName({ value: "other", label: "other" }); // Reset to "other"
  };

  //THIS IS GOING TO BE FOR THE DO AND DUE DATES

  const handleKanbanDragStart = (e, task) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(task));
  };

  const handleKanbanDragOver = (e) => {
    e.preventDefault();
  };

  const handleKanbanDrop = async (e, newStatus) => {
    e.preventDefault();
    const task = JSON.parse(e.dataTransfer.getData("text/plain"));

    if (task.status !== newStatus) {
      // Immediately update local state
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.task_id === task.task_id ? { ...t, status: newStatus } : t
        )
      );

      try {
        if (newStatus === "done") {
          playSound();
          await handleTaskCompletion(task.task_id, newStatus);
        } else {
          await changeStatus(task.task_id, newStatus);
        }
      } catch (error) {
        console.error("Error updating task status:", error);
        // Revert local state if API call fails
        setTasks((prevTasks) =>
          prevTasks.map((t) =>
            t.task_id === task.task_id ? { ...t, status: task.status } : t
          )
        );
      }
    }
  };

  // Near the top of your component, create the class options array
  const classOptions = classes
    ? [
        ...classes.map((c) => ({
          value: c.class_name,
          label: `${c.icon} ${c.class_name}`,
        })),
        { value: "other", label: "other" }, // Removed emoji from 'other'
      ]
    : [{ value: "other", label: "other" }];

  // Then update the Select component
  <Select
    className="select_container class_select"
    placeholder="Class" // This will show when nothing is selected
    value={input_class_name}
    onChange={(selectedOption) => setInputClassName(selectedOption)}
    options={classOptions}
    isClearable={true} // Allows clearing the selection
    defaultValue={null} // Starts with no selection
  />;

  // In your task rendering function
  const renderTask = (task) => {
    // Extract the emoji from the class_name (it's the first character)
    const classIcon = task.class_name?.split(" ")[0] || "📝";
    return (
      <div className="task_header">
        {task.title} {classIcon}
      </div>
    );
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editingTask, setEditingTask] = useState(null);


  const handleTaskClick = (task) => {
    setEditingTask(task);
    setTitle(task.title);
    setInputClassName({ value: task.class_name, label: task.class_name });
    setStatus({ value: task.status, label: task.status.charAt(0).toUpperCase() + task.status.slice(1) });
    
    // Fix timezone issues by setting time to noon
    const doDate = new Date(task.do_date + 'T12:00:00');
    const dueDate = new Date(task.due_date + 'T12:00:00');
    
    setInputDoDate(doDate);
    setInputDueDate(dueDate);
    setVisible(true);
    setIsEditing(true);
  };

  // Add update task function
  const updateTask = async () => {
    if (!title) return;

    try {
      // Format dates correctly for the API
     

      const formattedDoDate = format(input_do_date, 'yyyy-MM-dd');
      const formattedDueDate = format(input_due_date, 'yyyy-MM-dd');

      let lets_do_date = input_do_date;
      let lets_due_date = input_due_date;
  
      if (!isDateFormattedCorrectly(input_do_date)) {
      const adjustedDate = new Date(input_do_date.setMinutes(input_do_date.getMinutes() - input_do_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDoDate(formattedDate);
      lets_do_date = formattedDate;
    }
    
    if (!isDateFormattedCorrectly(input_due_date)) {
      const adjustedDate = new Date(input_due_date.setMinutes(input_due_date.getMinutes() - input_due_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDueDate(formattedDate);
      lets_due_date = formattedDate;
    }

      // Update title
      await fetch(`${BASE_URL}/api/task/title/student?username=${username}&task_id=${editingTask.task_id}&title=${title}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
     

      // Update class name
      if (input_class_name?.label) {
        await fetch(`${BASE_URL}/api/task/class_name/student?username=${username}&task_id=${editingTask.task_id}&class_name=${input_class_name.label}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },  
        });
      }
   

      // Update do date
      await changeDoDate(editingTask.task_id, lets_do_date);

      // Update due date
      await changeDueDate(editingTask.task_id, lets_due_date);

      // Update status
      await fetch(`${BASE_URL}/api/task/status_new/student?username=${username}&task_id=${editingTask.task_id}&status=${status.value}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    
      // Update local state with all changes
      const updatedTask = {
        ...editingTask,
        title: title,
        class_name: input_class_name?.label || editingTask.class_name,
        status: status.value,
        do_date: lets_do_date,
        due_date: lets_due_date,
      };

      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.task_id === editingTask.task_id ? updatedTask : task
        )
      );

      // Reset form
      setVisible(false);
      setIsEditing(false);
      setEditingTask(null);
      setTitle("");
      setStatus({ value: "todo", label: "Todo" });
      setInputClassName(null);
      
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  // Modify the modal submit button to handle both create and update
  const handleSubmit = () => {
    if (isEditing) {
      updateTask();
    } else {
      upload_task();
    }
  };

  return (
    <div
      className="haha"
      style={{ flexGrow: 1, backgroundColor: "#2b2b2b", width: "100%" }}
    >
      <Modal
        open={visible}
        onClose={() => setVisible(false)}
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div className="actuall_add_task_container">
          <TextField
            maxLength={24}
            label="Title"
            value={title}
            style={{
              width: "80%", // Changed from 80% to 40%
              backgroundColor: "ivory",
              color: "#2b2b2b",
            }}
            onChange={(e) => setTitle(e.target.value)}
            className="assign_task_title_container"
          />
          <div className="task_details_container">
            <div className="task_details_row">
              <div className="date_picker_container_titles">DO date</div>
              <div className="date_picker_container_titles">Due date</div>
            </div>
            <div className="task_details_row">
              <div className="date_picker_container">
                <DatePicker
                  className="date_pucker"
                  selected={theDoDate}
                  onChange={onChangeDoDate}
                />
              </div>
              <div className="date_picker_container">
                <DatePicker
                  className="date_pucker"
                  selected={theDueDate}
                  onChange={onChangeDueDate}
                />
              </div>
            </div>

            <div className="task_details_row">
              <div>
                <Select
                  className="select_container class_select" // Added status_select class
                  placeholder="Class"
                  value={input_class_name}
                  onChange={(selectedOption) =>
                    setInputClassName(selectedOption)
                  }
                  options={classOptions}
                  isClearable={true}
                  defaultValue={null}
                />

                <Select
                  className="select_container status_select" // Added class_select class
                  placeholder="Status"
                  value={status}
                  onChange={(selectedOption) => setStatus(selectedOption)}
                  options={[
                    { label: "To Do", value: "todo" },
                    { label: "Doing", value: "doing" },
                    { label: "Done", value: "done" },
                  ]}
                />
              </div>
              <button className="sign_in_butt_task" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="student-task-view">
        <div className="student-header">
          <button className="back-button" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} size="2xl" color="ivory" />
          </button>
          <h2 className="username_display">{username}</h2>
        </div>
      </div>

      <div className="quercus">
        <button
          className={`student_kan_ban_headers`}
          style={{ opacity: display === "kan-ban" ? 1 : 0.5 }}
          onClick={() => setDisplay("kan-ban")}
        >
          Kanban
        </button>
        <button
          className={`student_calendar_header`}
          style={{ opacity: display === "calendar" ? 1 : 0.5 }}
          onClick={() => setDisplay("calendar")}
        >
          Calendar
        </button>

        <button
          className={`statistics_header`}
          style={{ opacity: display === "statistics" ? 1 : 0.5 }}
          onClick={() => setDisplay("statistics")}
        >
          Stats
        </button>
      </div>

      {display === "kan-ban" ? (
        <div className="quercus">
          <div className="taskview-add-container">
            <button
              className="add_task_container"
              onClick={() => setVisible(true)}
            >
              Add Task
            </button>
          </div>
          <div className="task_and_routines_container">
            {/* TODO Column */}
            <div
              className="board_containers"
              onDragOver={handleKanbanDragOver}
              onDrop={(e) => handleKanbanDrop(e, "todo")}
            >
              <div className="todo_container">To Do</div>
              {taskList && taskList.length > 0 ? (
                taskList
                  .filter((item) => item.status === "todo")
                  .map((item, index) => (
                    <div
                      key={index.toString()}
                      className="new_task"
                      draggable="true"
                      onDragStart={(e) => handleKanbanDragStart(e, item)}
                      onClick={() => handleTaskClick(item)}
                    >
                      <div className="task_header">
                        <button
                          className="move-button"
                          onClick={(e) => {
                            deleteTask(item.task_id);
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            size="2xl"
                            style={{ color: "ivory" }}
                          />
                        </button>
                        <span className="task-text">
                          {item.title} {getClassIcon(item.class_name)}
                        </span>
                      </div>
                      {/* Removed the right arrow */}
                    </div>
                  ))
              ) : (
                <div className="no_tasks"></div>
              )}
            </div>

            {/* DOING Column */}
            <div
              className="board_containers"
              onDragOver={handleKanbanDragOver}
              onDrop={(e) => handleKanbanDrop(e, "doing")}
            >
              <div className="doing_container">Doing</div>
              {taskList && taskList.length > 0 ? (
                taskList
                  .filter((item) => item.status === "doing")
                  .map((item, index) => (
                    <div
                      key={index.toString()}
                      className="new_task"
                      draggable="true"
                      onDragStart={(e) => handleKanbanDragStart(e, item)}
                      onClick={() => handleTaskClick(item)}
                    >
                      <div className="task_header">
                        <button
                          className="move-button"
                          onClick={(e) => {
                            changeStatus(item.task_id, "done");
                            playSound();
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSquareCheck}
                            size="2xl"
                            style={{ color: "ivory" }}
                          />
                        </button>
                        <span className="task-text">
                          {item.title} {getClassIcon(item.class_name)}
                        </span>
                      </div>
                      {/* Removed the left arrow */}
                    </div>
                  ))
              ) : (
                <div className="no-tasks"></div>
              )}
            </div>

            {/* DONE Column */}
            <div
              className="board_containers"
              onDragOver={handleKanbanDragOver}
              onDrop={(e) => handleKanbanDrop(e, "done")}
            >
              <div className="done_container">Done</div>
              {taskList && taskList.length > 0 ? (
                taskList
                  .filter((item) => item.status === "done")
                  .sort((a, b) => {
                    // Convert dates to timestamps for comparison
                    const dateA = new Date(a.do_date + 'T12:00:00');
                    const dateB = new Date(b.do_date + 'T12:00:00');
                    return dateB - dateA; // Sort descending (newest first)
                  })
                  .slice(0, 10) // Take only the first 10 items
                  .map((item, index) => (
                    <div 
                      key={index.toString()} 
                      className="new_task"
                      draggable="true"
                      onDragStart={(e) => handleKanbanDragStart(e, item)}
                      onClick={() => handleTaskClick(item)}
                    >
                      <div className="task_header_done">
                        <span className="task-text">
                          {item.title} {getClassIcon(item.class_name)}
                        </span>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-tasks"></div>
              )}
            </div>
          </div>
        </div>
      ) : display === "calendar" ? (
        <div className="quercus">
          <div className="quercus">
            <button
              className={`do_date_header`}
              style={{ opacity: calendar_view === "do_date" ? 1 : 0.5 }}
              onClick={() => setCalendarView("do_date")}
            >
              DO date
            </button>
            <button
              className={`due_date_header`}
              style={{ opacity: calendar_view === "due_date" ? 1 : 0.5 }}
              onClick={() => setCalendarView("due_date")}
            >
              DUE date
            </button>
          </div>

          {calendar_view === "due_date" ? (
            <div className="quercus">
              <div className="construct">
                <div className="calendar_container">
                  <div className="month_name">{monthNames[selectedMonth]}</div>
                  <button className="month_button" onClick={previousMonth}>
                    {"<"}
                  </button>
                  <button className="month_button" onClick={nextMonth}>
                    {">"}
                  </button>
                </div>
                <div className="calendar_container">
                  <div className="day_container">
                    <div className="day_titles">Mon</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Tues</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Wed</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Thurs</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Fri</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Sat</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Sun</div>
                  </div>
                </div>
                {renderCalendar("due_date")}
              </div>
            </div>
          ) : (
            <div className="quercus">
              <div className="construct">
                <div className="calendar_container">
                  <div className="month_name">{monthNames[selectedMonth]}</div>
                  <button className="month_button" onClick={previousMonth}>
                    {"<"}
                  </button>
                  <button className="month_button" onClick={nextMonth}>
                    {">"}
                  </button>
                </div>
                <div className="calendar_container">
                  <div className="day_container">
                    <div className="day_titles">Mon</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Tues</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Wed</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Thurs</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Fri</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Sat</div>
                  </div>
                  <div className="day_container">
                    <div className="day_titles">Sun</div>
                  </div>
                </div>
                {renderCalendar("do_date")}
              </div>
            </div>
          )}
        </div>
      ) : display === "statistics" ? (
        <div style={{ backgroundColor: '#2b2b2b', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <div className="quercus">
            <button
              className={`kan_ban_headers`}
              style={{ opacity: stats_display === "days" ? 1 : 0.5 }}
              onClick={() => setStatsDisplay("days")}
            >
              Days
            </button>
            <button
              className={`calendar_header`}
              style={{ opacity: stats_display === "weeks" ? 1 : 0.5 }}
              onClick={() => setStatsDisplay("weeks")}
            >
              Weeks
            </button>
          </div>
          {username && (
            stats_display === "days" ? 
              <DayDisplay daysReport={statisticsData.days} /> : 
              <WeekDisplay weeksReport={statisticsData.weeks} />
          )}
        </div>
      ) : null}
    </div>
  );
}

const DayDisplay = ({ daysReport }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  // Update selectedDate only when daysReport changes significantly
  useEffect(() => {
    if (daysReport && !selectedDate) {
      const today = new Date().toISOString().split("T")[0];
      const dates = Object.keys(daysReport).sort();
      // If today exists in the data, use it; otherwise use the most recent date
      setSelectedDate(dates.includes(today) ? today : dates[dates.length - 1]);
    }
  }, [daysReport, selectedDate]);

  // Function to get active classes (those with any activity)
  const getActiveClasses = (data) => {
    if (!data) return [];
    const activeClasses = new Set();
    Object.values(data).forEach((day) => {
      Object.entries(day.poms_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
      Object.entries(day.tasks_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
    });
    return Array.from(activeClasses).filter((className) => className !== "");
  };

  // Generate colors for active classes
  const colorPalette_3 = ["#ef5656", "#ffd54f", "#64b5f6"];
  const colorPalette_6 = [
    "#ef5656",
    "#ffd54f",
    "#81c784",
    "#64b5f6",
    "#cd70ff",
    "#ff589e",
  ];
  const colorPalette_9 = [
    "#ef5656",
    "#ff8f50",
    "#ffd54f",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#6480f6",
    "#cd70ff",
    "#ff589e",
  ];
  const colorPalette_12 = [
    "#ef5656",
    "#ff8f50",
    "#ffb648",
    "#ffd54f",
    "#b0d169",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#6480f6",
    "#7d64f6",
    "#cd70ff",
    "#ff589e",
  ];
  const colorPalette_15 = [
    "#ef5656",
    "#ff8f50",
    "#ffb648",
    "#ffd54f",
    "#b0d169",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#4f90ff",
    "#6480f6",
    "#7d64f6",
    "#a464f6",
    "#cd70ff",
    "#f876cf",
    "#ff589e",
  ];

  const colorPalette = [
    "#ef5656",
    "#ff8f50",
    "#ffb648",
    "#ffd54f",
    "#b0d169",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#4f90ff",
    "#6480f6",
    "#7d64f6",
    "#a464f6",
    "#cd70ff",
    "#f876cf",
    "#ff589e",
  ];
  const activeClasses = daysReport ? getActiveClasses(daysReport) : [];
  const getColorPalette = (classCount) => {
    if (classCount <= 3) return colorPalette_3;
    if (classCount <= 6) return colorPalette_6;
    if (classCount <= 9) return colorPalette_9;
    if (classCount <= 12) return colorPalette_12;
    return colorPalette_15;
  };

  const classColors = Object.fromEntries(
    activeClasses.map((className, index) => {
      const selectedPalette = getColorPalette(activeClasses.length);
      return [className, selectedPalette[index % selectedPalette.length]];
    })
  );

  // Function to calculate maximum height for scaling
  const calculateMaxHeight = () => {
    let maxTotal = 0;
    Object.values(daysReport).forEach((day) => {
      const tasksTotal = Object.values(day.tasks_classes).reduce(
        (sum, val) => sum + val,
        0
      );
      const pomsTotal = Object.values(day.poms_classes).reduce(
        (sum, val) => sum + val,
        0
      );
      maxTotal = Math.max(maxTotal, tasksTotal, pomsTotal);
    });
    return maxTotal;
  };

  // Get dates sorted chronologically
  const dates = daysReport ? Object.keys(daysReport).sort() : [];
  const lastSevenDays = dates.slice(-7);

  // Return loading state if data isn't ready
  if (!daysReport || !selectedDate) {
    return <div style={{ color: "ivory" }}>Loading...</div>;
  }

  // Function to get day name from date
  const getDayName = (dateString) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  // Function to get next/previous date
  const changeDate = (direction) => {
    const sortedDates = Object.keys(daysReport).sort();
    const currentIndex = sortedDates.indexOf(selectedDate);
    if (direction === "next" && currentIndex < sortedDates.length - 1) {
      setSelectedDate(sortedDates[currentIndex + 1]);
    } else if (direction === "prev" && currentIndex > 0) {
      setSelectedDate(sortedDates[currentIndex - 1]);
    }
  };

  // Calculate metrics for selected day
  const selectedDayData = daysReport[selectedDate];
  const totalMinutes =
    selectedDayData.poms_25 * 25 + selectedDayData.poms_45 * 45;

  return (
    <div
      className="statistics-container"
      style={{
        padding: "0",
        color: "ivory",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#2b2b2b",
        width: "100%",
        height: "90vh",
        position: "relative",
      }}
    >
      {/* Chart container */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4vw",
          width: "90%",
          maxWidth: "1400px",
          margin: "0 auto",
        }}
      >
        {/* Left side - Legend */}
        <div
          style={{
            paddingBottom: "3vh",
            fontSize: "1.2vw",
            width: "12vw",
          }}
        >
          {activeClasses.map((cls) => (
            <div
              key={cls}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1vh",
              }}
            >
              <div
                style={{
                  width: "1.5vw",
                  height: "1.5vw",
                  backgroundColor: classColors[cls],
                  marginRight: "1vw",
                  borderRadius: "3px",
                }}
              ></div>
              <span>{cls}</span>
            </div>
          ))}
        </div>

        {/* Center - Chart */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50vw",
            maxWidth: "800px",
            margin: "0",
          }}
        >
          {/* Navigation */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2vw",
              marginBottom: "8vh",
              marginTop: "-25vh",
              transform: "scale(1.5)",
            }}
          >
            <button
              onClick={() => changeDate("prev")}
              style={{
                fontSize: "2.5vw",
                background: "none",
                border: "none",
                color: "ivory",
                cursor: "pointer",
                opacity:
                  Object.keys(daysReport).sort().indexOf(selectedDate) > 0
                    ? 1
                    : 0.5,
              }}
            >
              ←
            </button>
            <h2
              style={{
                margin: 0,
                color: "ivory",
                fontSize: "2.5vw",
              }}
            >
              {getDayName(selectedDate)}
            </h2>
            <button
              onClick={() => changeDate("next")}
              style={{
                fontSize: "2.5vw",
                background: "none",
                border: "none",
                color: "ivory",
                cursor: "pointer",
                opacity:
                  Object.keys(daysReport).sort().indexOf(selectedDate) <
                  Object.keys(daysReport).length - 1
                    ? 1
                    : 0.5,
              }}
            >
              →
            </button>
          </div>

          {/* Graph area */}
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              height: "40vh",
              position: "relative",
              justifyContent: "center",
            }}
          >
            {/* Y-axis */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                marginRight: "1vw",
                fontSize: "1.2vw",
              }}
            >
              {[...Array(6)].map((_, i) => {
                const value = Math.round((calculateMaxHeight() * (5 - i)) / 5);
                return <span key={i}>{`${value}|${value * 25}`}</span>;
              })}
            </div>

            {/* Bars */}
            <div
              style={{
                display: "flex",
                gap: "2vw",
                height: "100%",
                alignItems: "flex-end",
              }}
            >
              {lastSevenDays.map((date) => {
                const dayData = daysReport[date];
                return (
                  <div
                    key={date}
                    style={{
                      display: "flex",
                      gap: "0.5vw",
                      height: "100%",
                      opacity: date === selectedDate ? 1 : 0.7, // Highlight selected day
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    {/* Tasks bar */}
                    <div
                      style={{
                        width: "1.5vw",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {activeClasses.map(
                        (className) =>
                          dayData.tasks_classes[className] > 0 && (
                            <div
                              key={`${date}-${className}-tasks`}
                              style={{
                                height: `${
                                  (dayData.tasks_classes[className] /
                                    calculateMaxHeight()) *
                                  40
                                }vh`,
                                backgroundColor: classColors[className],
                                borderRadius: "3px",
                                marginBottom: "-1px",
                              }}
                            />
                          )
                      )}
                    </div>
                    {/* Pomodoros bar */}
                    <div
                      style={{
                        width: "1.5vw",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {activeClasses.map(
                        (className) =>
                          dayData.poms_classes[className] > 0 && (
                            <div
                              key={`${date}-${className}-poms`}
                              style={{
                                height: `${
                                  (dayData.poms_classes[className] /
                                    calculateMaxHeight()) *
                                  40
                                }vh`,
                                backgroundColor: classColors[className],
                                borderRadius: "3px",
                                marginBottom: "-1px",
                              }}
                            />
                          )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* X-axis */}
          <div
            style={{
              display: "flex",
              gap: "2vw",
              marginTop: "1vh",
              color: "ivory",
              width: "100%",
              paddingLeft: "4vw",
              fontSize: "1.2vw",
              justifyContent: "center",
            }}
          >
            {[
              { day: "m", label: "Mon" },
              { day: "t1", label: "Tue" },
              { day: "w", label: "Wed" },
              { day: "t2", label: "Thu" },
              { day: "f", label: "Fri" },
              { day: "s1", label: "Sat" },
              { day: "s2", label: "Sun" },
            ].map(({ day, label }) => (
              <div
                key={day}
                style={{
                  width: "3.5vw",
                  textAlign: "center",
                }}
              >
                {label[0].toLowerCase()}
              </div>
            ))}
          </div>
        </div>

        {/* Right side - Metrics */}
        <div
          style={{
            paddingBottom: "27vh",
            textAlign: "right",
            width: "12vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2vh",
            }}
          >
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "7vw", margin: "0" }}>
                {selectedDayData.tasks_done}
              </h1>
              <span
                style={{
                  position: "absolute",
                  bottom: "-1vw",
                  right: "-2vw",
                  fontSize: "2vw",
                  color: "ivory",
                  opacity: 0.8,
                }}
              >
                tasks
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "7vw", margin: "0" }}>
                {selectedDayData.pomodoros_done}
              </h1>
              <span
                style={{
                  position: "absolute",
                  bottom: "-1vw",
                  right: "-2vw",
                  fontSize: "2vw",
                  color: "ivory",
                  opacity: 0.8,
                }}
              >
                poms
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "7vw", margin: "0" }}>{totalMinutes}</h1>
              <span
                style={{
                  position: "absolute",
                  //bottom: '0',
                  right: "-2vw",
                  bottom: "-1vw",
                  fontSize: "2vw",
                  color: "ivory",
                  opacity: 0.8,
                }}
              >
                mins
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WeekDisplay = ({ weeksReport }) => {
  const [selectedWeek, setSelectedWeek] = useState(null);

  useEffect(() => {
    const weeks = Object.keys(weeksReport).sort();
    setSelectedWeek(weeks[weeks.length - 1]);
  }, [weeksReport]);

  // Function to get active classes (those with any activity)
  const getActiveClasses = (data) => {
    if (!data) return [];
    const activeClasses = new Set();
    Object.values(data).forEach((week) => {
      Object.entries(week.poms_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
      Object.entries(week.tasks_classes).forEach(([className, count]) => {
        if (count > 0) activeClasses.add(className);
      });
    });
    return Array.from(activeClasses).filter((className) => className !== "");
  };

  const colorPalette_3 = ["#ef5656", "#ffd54f", "#64b5f6"];
  const colorPalette_6 = [
    "#ef5656",
    "#ffd54f",
    "#81c784",
    "#64b5f6",
    "#cd70ff",
    "#ff589e",
  ];
  const colorPalette_9 = [
    "#ef5656",
    "#ff8f50",
    "#ffd54f",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#6480f6",
    "#cd70ff",
    "#ff589e",
  ];
  const colorPalette_12 = [
    "#ef5656",
    "#ff8f50",
    "#ffb648",
    "#ffd54f",
    "#b0d169",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#6480f6",
    "#7d64f6",
    "#cd70ff",
    "#ff589e",
  ];
  const colorPalette_15 = [
    "#ef5656",
    "#ff8f50",
    "#ffb648",
    "#ffd54f",
    "#b0d169",
    "#81c784",
    "#4db6ac",
    "#64b5f6",
    "#4f90ff",
    "#6480f6",
    "#7d64f6",
    "#a464f6",
    "#cd70ff",
    "#f876cf",
    "#ff589e",
  ];

  const colorPalette = [
    "#E57373",
    "#FFB74D",
    "#81C784",
    "#64B5F6",
    "#BA68C8",
    "#4DB6AC",
    "#FFD54F",
    "#F06292",
  ];
  const activeClasses = weeksReport ? getActiveClasses(weeksReport) : [];
  const getColorPalette = (classCount) => {
    if (classCount <= 3) return colorPalette_3;
    if (classCount <= 6) return colorPalette_6;
    if (classCount <= 9) return colorPalette_9;
    if (classCount <= 12) return colorPalette_12;
    return colorPalette_15;
  };

  const classColors = Object.fromEntries(
    activeClasses.map((className, index) => {
      const selectedPalette = getColorPalette(activeClasses.length);
      return [className, selectedPalette[index % selectedPalette.length]];
    })
  );

  const calculateMaxHeight = () => {
    let maxTotal = 0;
    Object.values(weeksReport || {}).forEach((week) => {
      const tasksTotal = Object.values(week.tasks_classes).reduce(
        (sum, val) => sum + val,
        0
      );
      const pomsTotal = Object.values(week.poms_classes).reduce(
        (sum, val) => sum + val,
        0
      );
      maxTotal = Math.max(maxTotal, tasksTotal, pomsTotal);
    });
    return maxTotal;
  };

  const formatDateRange = (startDate) => {
    const start = new Date(startDate);
    const end = new Date(weeksReport[startDate].week_end);
    return `${start.getMonth() + 1}/${start.getDate()} - ${
      end.getMonth() + 1
    }/${end.getDate()}`;
  };

  const changeWeek = (direction) => {
    const sortedWeeks = Object.keys(weeksReport).sort();
    const currentIndex = sortedWeeks.indexOf(selectedWeek);
    if (direction === "next" && currentIndex < sortedWeeks.length - 1) {
      setSelectedWeek(sortedWeeks[currentIndex + 1]);
    } else if (direction === "prev" && currentIndex > 0) {
      setSelectedWeek(sortedWeeks[currentIndex - 1]);
    }
  };

  if (!weeksReport || !selectedWeek) {
    return <div style={{ color: "ivory" }}>Loading...</div>;
  }

  const selectedWeekData = weeksReport[selectedWeek];
  const totalMinutes =
    selectedWeekData.poms_25 * 25 + selectedWeekData.poms_45 * 45;
  const weeks = Object.keys(weeksReport).sort();

  return (
    <div
      className="statistics-container"
      style={{
        padding: "0",
        color: "ivory",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#2b2b2b",
        width: "100%",
        height: "90vh",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4vw",
          width: "90%",
          maxWidth: "1400px",
          margin: "0 auto",
        }}
      >
        {/* Left side - Legend */}
        <div
          style={{
            paddingBottom: "3vh",
            fontSize: "1.2vw",
            width: "12vw",
          }}
        >
          {activeClasses.map((cls) => (
            <div
              key={cls}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1vh",
              }}
            >
              <div
                style={{
                  width: "1.5vw",
                  height: "1.5vw",
                  backgroundColor: classColors[cls],
                  marginRight: "1vw",
                  borderRadius: "3px",
                }}
              ></div>
              <span>{cls}</span>
            </div>
          ))}
        </div>

        {/* Center - Chart */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50vw",
            maxWidth: "800px",
            margin: "0",
          }}
        >
          {/* Navigation */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2vw",
              marginBottom: "8vh",
              marginTop: "-25vh",
              transform: "scale(1.5)",
            }}
          >
            <button
              onClick={() => changeWeek("prev")}
              style={{
                fontSize: "2.5vw",
                background: "none",
                border: "none",
                color: "ivory",
                cursor: "pointer",
                opacity:
                  Object.keys(weeksReport).sort().indexOf(selectedWeek) > 0
                    ? 1
                    : 0.5,
              }}
            >
              ←
            </button>
            <h2
              style={{
                margin: 0,
                color: "ivory",
                fontSize: "2.5vw",
              }}
            >
              {formatDateRange(selectedWeek)}
            </h2>
            <button
              onClick={() => changeWeek("next")}
              style={{
                fontSize: "2.5vw",
                background: "none",
                border: "none",
                color: "ivory",
                cursor: "pointer",
                opacity:
                  Object.keys(weeksReport).sort().indexOf(selectedWeek) <
                  Object.keys(weeksReport).length - 1
                    ? 1
                    : 0.5,
              }}
            >
              →
            </button>
          </div>

          {/* Graph area */}
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              height: "40vh",
              position: "relative",
              justifyContent: "center",
            }}
          >
            {/* Y-axis */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                marginRight: "1vw",
                fontSize: "1.2vw",
              }}
            >
              {[...Array(6)].map((_, i) => {
                const value = Math.round((calculateMaxHeight() * (5 - i)) / 5);
                return <span key={i}>{`${value}|${value * 25}`}</span>;
              })}
            </div>

            {/* Bars */}
            <div
              style={{
                display: "flex",
                gap: "2vw",
                height: "100%",
                alignItems: "flex-end",
              }}
            >
              {weeks.map((week) => {
                const weekData = weeksReport[week];
                return (
                  <div
                    key={week}
                    style={{
                      display: "flex",
                      gap: "0.5vw",
                      height: "100%",
                      opacity: week === selectedWeek ? 1 : 0.7,
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    {/* Tasks bar */}
                    <div
                      style={{
                        width: "1.5vw",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {activeClasses.map(
                        (className) =>
                          weekData.tasks_classes[className] > 0 && (
                            <div
                              key={`${week}-${className}-tasks`}
                              style={{
                                height: `${
                                  (weekData.tasks_classes[className] /
                                    calculateMaxHeight()) *
                                  40
                                }vh`,
                                backgroundColor: classColors[className],
                                borderRadius: "3px",
                                marginBottom: "-1px",
                              }}
                            />
                          )
                      )}
                    </div>
                    {/* Pomodoros bar */}
                    <div
                      style={{
                        width: "1.5vw",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {activeClasses.map(
                        (className) =>
                          weekData.poms_classes[className] > 0 && (
                            <div
                              key={`${week}-${className}-poms`}
                              style={{
                                height: `${
                                  (weekData.poms_classes[className] /
                                    calculateMaxHeight()) *
                                  40
                                }vh`,
                                backgroundColor: classColors[className],
                                borderRadius: "3px",
                                marginBottom: "-1px",
                              }}
                            />
                          )
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* X-axis */}
          <div
            style={{
              display: "flex",
              gap: "2vw",
              marginTop: "1vh",
              color: "ivory",
              width: "100%",
              paddingLeft: "4vw",
              fontSize: "1.2vw",
              justifyContent: "center",
            }}
          >
            {weeks.map((week) => {
              const date = new Date(week);
              return (
                <div
                  key={week}
                  style={{
                    width: "3.5vw",
                    textAlign: "center",
                  }}
                >
                  {`${date.getMonth() + 1}/${date.getDate()}`}
                </div>
              );
            })}
          </div>
        </div>

        {/* Right side - Metrics */}
        <div
          style={{
            paddingBottom: "27vh",
            textAlign: "right",
            width: "12vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2vh",
            }}
          >
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "7vw", margin: "0" }}>
                {selectedWeekData.tasks_done}
              </h1>
              <span
                style={{
                  position: "absolute",
                  bottom: "-1vw",
                  right: "-2vw",
                  fontSize: "2vw",
                  color: "ivory",
                  opacity: 0.8,
                }}
              >
                tasks
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "7vw", margin: "0" }}>
                {selectedWeekData.pomodoros_done}
              </h1>
              <span
                style={{
                  position: "absolute",
                  bottom: "-1vw",
                  right: "-2vw",
                  fontSize: "2vw",
                  color: "ivory",
                  opacity: 0.8,
                }}
              >
                poms
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "7vw", margin: "0" }}>{totalMinutes}</h1>
              <span
                style={{
                  position: "absolute",
                  right: "-2vw",
                  bottom: "-1vw",
                  fontSize: "2vw",
                  color: "ivory",
                  opacity: 0.8,
                }}
              >
                mins
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
