import React, { createContext, useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import useToken  from "./TokenContext";
import { BASE_URL } from "./Base_url.js";
import pomoSound from "./level-up-191997.mp3";
import { useAudio } from './AudiContext';
import { min } from 'date-fns';




const PomodoroContext = createContext();

export function usePomodoro() {
  return useContext(PomodoroContext);
}

export const PomodoroProvider = ({ children }) => {

    const [isRunning, setIsRunning] = useState(false);
    const [isBreak, setIsBreak] = useState(false);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const { token, refreshToken, profile, updatePomosThisWeek, class_names } = useToken();
    const [pomostoday, setPomostoday] = useState(0);
    const canvasRef = useRef(null);
    const startTimeRef = useRef(null);
    const animationFrameRef = useRef(null);
    const [class_name, setClassName] = useState("other")
    const { playSound, audioRef } = useAudio();
    const [classes, setClasses] = useState([]);
    const [input_class_name, setInputClassName] = useState(null);
    const [pomoLength, setPomoLength] = useState([25,5]);
    const [mainTimer, setMainTimer] = useState(    pomoLength[0]    * 60);
    const [breakTimer, setBreakTimer] = useState(pomoLength[1] * 60);

    useEffect(() => {
      const fetchClasses = async () => {
        if (profile && profile[0] && token) {
          try {
            const response = await fetch(`${BASE_URL}/api/class?username=${profile[0].username}`, {
              method: "GET",
              headers: {
                "accept": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            });

            if (response.status === 401) {
              await refreshToken();
              return fetchClasses();
            }

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setClasses(data);
          } catch (error) {
            console.error("Error fetching classes:", error);
          }
        }
      };

      fetchClasses();
    }, [profile, token, refreshToken]);

    const classOptions = useMemo(() => {
      if (!classes || classes.length === 0) {
        return [{ value: 'other', label: 'other' }];
      }
      return [
        ...classes.map(c => ({
          value: c.class_name,
          label: `${c.icon} ${c.class_name}`
        })),
        { value: 'other', label: 'other' }
      ];
    }, [classes]);

    const upload_pomo = async () => {
      if (!profile || !profile[0]) {
        console.error("Profile not loaded");
        return;
      }

      const currentDate = new Date().toISOString().split('T')[0];
      const pomoObject = {
        username: profile[0].username,
        pomodoro_id: "ha",
        length: pomoLength[0],
        completed: true,
        completion_date: currentDate,
        creation_date: currentDate,
        class_name: input_class_name ? input_class_name.value : 'other'
      };


      try {
        const response = await fetch(`${BASE_URL}/api/pomodoro`, {
          method: "POST",
          headers: {
            'accept': 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(pomoObject)
        });

        if (response.status === 401) {
          await refreshToken();
          return upload_pomo(); // Retry after token refresh
        } 
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Update statistics after successful upload
        await Promise.all([
          updatePomosThisWeek(),
          setPomostoday(prev => prev + 1)
        ]);

      } catch (error) {
        console.error("Error uploading pomodoro:", error);
        throw error;
      }
    };

    const handleTimerComplete = async () => {
      console.log("Timer Complete Called");
      
      // Clear the timer first
      clearTimeout(animationFrameRef.current);
      
      // Play sound immediately when timer completes
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        await audioRef.current.play();
      }
      
      // Then handle all state updates
      localStorage.removeItem('startTime');
      localStorage.removeItem('isBreak');
      setIsRunning(false);
      
      if (!isBreak) {
        console.log("Main Timer Complete");
        setBreakTimer(pomoLength[1] * 60);
        setIsBreak(true);
        await upload_pomo();
        updatePomosThisWeek();
        setPomostoday(prev => prev + 1);
      } else {
        console.log("Break Timer Complete");
        setMainTimer(pomoLength[0] * 60);
        setIsBreak(false);
      }
    };

    const updateTimer = useCallback(() => {
      if (!startTimeRef.current || !isRunning) return;

      const now = Date.now();
      const totalDuration = (isBreak ? pomoLength[1] : pomoLength[0]) * 60 * 1000;
      const elapsed = now - startTimeRef.current;
      const remaining = Math.max(0, totalDuration - elapsed);
      
      // Calculate exact seconds remaining
      const exactSecondsRemaining = Math.ceil(remaining / 1000);
      
      // Update timer state
      if (isBreak) {
        setBreakTimer(exactSecondsRemaining);
      } else {
        setMainTimer(exactSecondsRemaining);
      }

      // Update page title with current timer
      const mins = Math.floor(exactSecondsRemaining / 60);
      const secs = exactSecondsRemaining % 60;
      document.title = `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')} ${isBreak ? 'Break' : 'Focus'} | Doing Done`;

      if (remaining <= 0) {
        handleTimerComplete();
        document.title = 'Doing Done';
        return;
      }

      // Schedule next update to align with the next second
      const nextSecondIn = 1000 - (elapsed % 1000);
      animationFrameRef.current = setTimeout(updateTimer, nextSecondIn);
    }, [isRunning, isBreak, pomoLength]);

    // Add cleanup effect to reset title when component unmounts
    useEffect(() => {
      return () => {
        document.title = 'Doing Done';
      };
    }, []);

    // Start/stop timer
    useEffect(() => {
      if (isRunning && !startTimeRef.current) {
        startTimeRef.current = Date.now();
        requestAnimationFrame(updateTimer);
      } else if (!isRunning) {
        startTimeRef.current = null;
        clearTimeout(animationFrameRef.current);
      }

      return () => {
        clearTimeout(animationFrameRef.current);
      };
    }, [isRunning, updateTimer]);

    // Handle page reload/tab visibility
    useEffect(() => {
      const savedStartTime = localStorage.getItem('startTime');
      const savedIsBreak = localStorage.getItem('isBreak') === 'true';
      
      if (savedStartTime) {
        const now = Date.now();
        const startTime = parseInt(savedStartTime, 10);
        const totalDuration = (savedIsBreak ? pomoLength[1] : pomoLength[0]) * 60 * 1000;
        const elapsed = now - startTime;
        const remaining = Math.max(0, totalDuration - elapsed);
        
        // Only restore if there's time remaining
        if (remaining > 0) {
          startTimeRef.current = startTime;
          setIsBreak(savedIsBreak);
          setIsRunning(true);
          
          // Set the correct timer
          const remainingSeconds = Math.ceil(remaining / 1000);
          if (savedIsBreak) {
            setBreakTimer(remainingSeconds);
          } else {
            setMainTimer(remainingSeconds);
          }

          // Start the timer immediately
          requestAnimationFrame(updateTimer);
        } else {
          // Clean up localStorage if timer would have expired
          localStorage.removeItem('startTime');
          localStorage.removeItem('isBreak');
          
          // Reset timers to their initial state
          if (savedIsBreak) {
            setMainTimer(pomoLength[0] * 60);
            setIsBreak(false);
          } else {
            setBreakTimer(pomoLength[1] * 60);
            setIsBreak(true);
          }
        }
      }
    }, [pomoLength, updateTimer]);

    // Add visibility change handler
    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          // Page is hidden, save current state
          if (isRunning && startTimeRef.current) {
            localStorage.setItem('startTime', startTimeRef.current.toString());
            localStorage.setItem('isBreak', isBreak.toString());
          }
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [isRunning, isBreak]);

    // Calculate progress and time display values
    const timer = isBreak ? breakTimer : mainTimer;
    const maxTime = isBreak ? pomoLength[1] * 60 :     pomoLength[0]     * 60;
    const progress = timer / maxTime;
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    useEffect(() => {
      if (profile && profile[0]) {
        fetch(`${BASE_URL}/api/pomodoro/done_today?username=${profile[0].username}`, {
          method: "GET",
          headers: {
            "accept": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => setPomostoday(data))
          .catch((error) => console.error("Error:", error));
      }
    }, [profile]);

    return (
      <PomodoroContext.Provider value={{ 
        mainTimer, 
        setMainTimer, 
        breakTimer, 
        setBreakTimer, 
        isRunning, 
        setIsRunning, 
        isBreak, 
        setIsBreak, 
        pomostoday, 
        canvasRef, 
        progress, 
        minutes, 
        seconds,
        classOptions,
        input_class_name,
        setInputClassName,
        pomoLength, setPomoLength
      }}>
        {children}
      </PomodoroContext.Provider>
    );
};
