import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import I_know_its_an_image_im_rushed from "./I_know_its_an_image_im_rushed.png";
import simpleLogo from "./simpleLogo.png";
import { TokenContext } from "./TokenContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "./Base_url";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";



export default function SignIn({ onSignIn }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { token, setToken } = useContext(TokenContext);
  const [display, setDisplay] = useState("website_display");
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [userConfirm, setUserConfirm] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [inputConfirmationCode, setInputConfirmationCode] = useState("");
  const [showPassword_1, setShowPassword_1] = useState(false);



  const randomCodeGenerator = () => {
    return Math.random().toString(36).substr(2, 9);
  };


  const pickImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setImage({
        uri: reader.result,
        base64: reader.result.split(',')[1],
        fileName: file.name,
      });
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  };
    


  const upload_image = async () => {
    if (!image?.base64) {
        alert('No image selected');
        return null;
      }
    
      const data = {
        image: image.base64, // Use the Base64 data directly
        filename: image.fileName,
      };

    let jsonData;
    try {
      jsonData = JSON.stringify(data);
    } catch (error) {
      
      return null;
    }

    const response = await fetch(`${BASE_URL}/api/wotd/image`, {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    

    const image_url = await response.json();


    if (response.status === 200) {
      return image_url;
    } else {
      alert("There was an error uploading the profile picture");
      return null;
    }
  };


  const createAccount = async() => {
    // Perform sign-in logic here (e.g., make API call)

    let image_url = await upload_image();


    
    if (username === "") {
      alert("Please enter a username");
      return;
    }
    if (email === "") {
      alert("Please enter an email");
      return;
    }
    if (password === "") {
      alert("Please enter a password");
      return;
    }


    let data = {
      username: username,
      password: password,
      email: email,
      profile_picture: image_url,
      creation_date: new Date(),
      disabled: false,
      role: "student",
      students: []

    };

    let jsonData;

    try{
      jsonData = JSON.stringify(data);
    } catch(error) {
    
      return null;

    }

    try {
      const response = await fetch(`${BASE_URL}/api/user`, {
        method: "POST",
        body: jsonData,
        headers: {
          'accept': 'application/json',
          "Content-Type": "application/json",
        },
      });

      if(response.status === 200) {
        setSuccess(true);
        setErrorMessage("Accound Sucessfully Created");
      } else if (response.status === 400) {
        setErrorMessage("Username already taken");
      }
    } catch (error) {
   
        setErrorMessage("Username already taken");
      }
  };

  useEffect(() => {
    if(success) {
      handleSignIn();
    }
  }, [success]);




  const handleSignIn = () => {
    // Perform sign-in logic here (e.g., make API call)
    fetch(`${BASE_URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${username}&password=${password}&grant_type=password`,
    })
      .then((response) => response.json())
      .then((data) => {
    
        if (data.access_token && data.refresh_token) {
          // If sign-in is successful, call onSignIn
          
          setToken(data.access_token);
          localStorage.setItem('refreshToken', data.refresh_token.toString());
          onSignIn();
        } else {
          // Handle sign-in failure (e.g., show an error message)
        
          setErrorMessage("Error incorrect username or password");
        }
      })
      .catch((error) => {
        // Handle request failure (e.g., network error)
    
        setErrorMessage("Error incorrect username or password");
      });
  };

  const emailConfirm = async () => {
    const code = randomCodeGenerator();
    const response = await fetch(`${BASE_URL}/api/user/email_confirm?username=${username}&email=${email}&confirmation_code=${code}`);
  
    if(response.status === 200) {
      setUserConfirm(true);
      setConfirmationCode(code);
     
      setErrorMessage("");
    } else {
      setErrorMessage("No user with this email");
    }

  };


  const confirmTheCode = async () => {
    if (inputConfirmationCode === confirmationCode) {
      setDisplay("actual_create_account");
      setErrorMessage("");
    } else {
      setErrorMessage("Incorrect code");
    }
  };

  const createNewPassword = async () => {
    if (newPassword === newPasswordConfirm) {
      
      setErrorMessage("");
      const response = await fetch(`${BASE_URL}/api/user/password?username=${username}&password=${newPassword}`, {
        method: "PUT",
      });
      if (response.status === 200) {
        setDisplay("login");
      } else {
        setErrorMessage("Error updating password");
      }
    } else {
      setErrorMessage("Passwords do not match");
    }
  };

  return (
    <div className="whynot">
      {display === "login"? (
        <>
        <div className="student-header">
          <button className="back-button" onClick={() => setDisplay("website_display")}>
            <FontAwesomeIcon icon={faArrowLeft} size="2xl" color="ivory" />
          </button>
        </div>
       
      <img
        className="logo"
        src="https://imagestoragebeta.s3.us-west-1.amazonaws.com/DoingDoneFullLogoWBG+(3.5+x+1+in).png"
        alt="DoingDone Logo"
        onClick={() => setDisplay("website_display")}
      />
      <p className="warning">{errorMessage}</p>
      <input
        id="username"

        className="sign"
        placeholder="Username"
        value={username}
        onChange={(e) => {
            setUsername(e.target.value);
          }}
      />
      <div>
      <input
        id="password"
        className="sign"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
            setPassword(e.target.value);
          }}
      />
      <button onClick={() => setShowPassword(!showPassword)}className="move-button">
        <FontAwesomeIcon icon={!showPassword? faEyeSlash : faEye} size="2xl"
                          style={{ color: "ivory" }}/>
      </button>
      </div>
      <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", width: "20%"}}>
      <button className="sign_in_butt_inital" onClick={handleSignIn}>
        <span className="text">Sign In</span>
      </button>
      <button className="reset_password_butt" onClick={() => setDisplay("resetPassword")}>
        <span className="text">Reset Password</span>
      </button>
      <p className="text">New here?</p>
      <button className="sign_in_create" onClick={() => setDisplay("createAcc")}>
        <span className="text">Create</span>
      </button>
      </div>
      </>
      ): display === "createAcc" ? (
        <>

      <div className="student-header">
          <button className="back-button" onClick={() => setDisplay("login")}>
            <FontAwesomeIcon icon={faArrowLeft} size="2xl" color="ivory" />
          </button>
        </div>
       
      <img
        className="logo"
        src="https://imagestoragebeta.s3.us-west-1.amazonaws.com/DoingDoneFullLogoWBG+(3.5+x+1+in).png"
        alt="DoingDone Logo"
        onClick={() => setDisplay("website_display")}
      />
      <p className={success ? "created" : "warning"}>{errorMessage}</p>
      <div style = {{justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center"}}>
      <div  className="profile_image_box" onClick={() => document.getElementById('fileInput').click()}>
        {image ? (
            <img src={image.uri} alt="Picked" style={{ width: "100%", height: "100%", borderRadius: 10 }} />
        ) : (
            <p className="welcome">Profile Pic</p>
        )}
        <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={pickImage}
            accept="image/*"
        />
        </div>

        <input
        id="username"
        maxLength="8"
        className="sign"
        placeholder="Username"
        value={username}
        onChange={(e) => {
            setUsername(e.target.value);
          }}
      />
      </div>
      <input
        id="email"
        className="sign"
        placeholder="Email"
        value={email}
        onChange={(e) => {
            setEmail(e.target.value);
          }}
      />
      <div>
      <input
        id="password"
        className="sign"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
            setPassword(e.target.value);
          }}
      />
      <button onClick={() => setShowPassword(!showPassword)}className="move-button">
        <FontAwesomeIcon icon={!showPassword? faEyeSlash : faEye} size="2xl"
                          style={{ color: "ivory" }}/>
      </button>
      </div>
      
      <div style={{justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center", width: "20%"}}>
      <button className="sign_in_create" onClick={createAccount}>
        <span className="text">Create</span>
      </button>
    
      </div>
      </>     

      ): display === "resetPassword" ? (
        <>

        <div className="student-header">
          <button className="back-button" onClick={() => setDisplay("login")}>
            <FontAwesomeIcon icon={faArrowLeft} size="2xl" color="ivory" />
          </button>
        </div>
       
        <img
          className="logo"
          src="https://imagestoragebeta.s3.us-west-1.amazonaws.com/DoingDoneFullLogoWBG+(3.5+x+1+in).png"
          alt="DoingDone Logo"
          onClick={() => setDisplay("website_display")}
        />
        <p className="warning">{errorMessage}</p>
        <input
          id="username"

          className="sign"
          placeholder="Username"
          value={username}
          onChange={(e) => {
              setUsername(e.target.value);
            }}
        />
        <div>
        <input
        id="email"
        className="sign"
        placeholder="Email"
        value={email}
        onChange={(e) => {
            setEmail(e.target.value);
          }}
         />
        </div>
        <div style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", width: "20%"}}>
        <button className="reset_password_butt" onClick={emailConfirm}>
          <span className="text">Send Email</span>
        </button>
        {userConfirm ? (
          <>
        <input
        id="confirmationCode"
        className="sign"
        placeholder="Confirmation Code"
        value={inputConfirmationCode}
        onChange={(e) => {
            setInputConfirmationCode(e.target.value);
          }}
        />
        <button className="sign_in_create" onClick={confirmTheCode}>
          <span className="text">Confirm</span>
        </button>
        </>
        ) : (
          <></>
        )}
        </div>
        </>
      ) : display === "actual_create_account" ? (
        <>
        <div className="student-header">
          <button className="back-button" onClick={() => setDisplay("login")}>
            <FontAwesomeIcon icon={faArrowLeft} size="2xl" color="ivory" />
          </button>
        </div>
       
        <img
          className="logo"
          src="https://imagestoragebeta.s3.us-west-1.amazonaws.com/DoingDoneFullLogoWBG+(3.5+x+1+in).png"
          alt="DoingDone Logo"
          onClick={() => setDisplay("website_display")}
        />
        <p className="warning">{errorMessage}</p>
        <div>
        <input
          id="new_password"
          className="sign"
          placeholder="New Password"
          type={showPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => {
              setNewPassword(e.target.value);
            }}
        />
        <button onClick={() => setShowPassword(!showPassword)}className="move-button">
        <FontAwesomeIcon icon={!showPassword? faEyeSlash : faEye} size="2xl"
                          style={{ color: "ivory" }}/>
      </button>
      </div>
        <div>
        <input
          id="confirmPassword"
          className="sign"
          placeholder="Confirm Password"
          type={showPassword_1 ? "text" : "password"}
          value={newPasswordConfirm}
          onChange={(e) => {
            setNewPasswordConfirm(e.target.value);
          }}
        />
        <button onClick={() => setShowPassword_1(!showPassword_1)}className="move-button">
        <FontAwesomeIcon icon={!showPassword_1? faEyeSlash : faEye} size="2xl"
                          style={{ color: "ivory" }}/>
      </button>
        </div>
        <div style={{ justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center", width: "20%"}}>
        <button className="sign_in_butt_inital" onClick={createNewPassword}>
          <span className="text">Set New Password</span>
        </button>
        </div>
        </>
      ) : (

        <div>
          <div style = {{display: "flex", justifyContent: "space-between", padding: "10px"}}>
            <img src={simpleLogo} alt="Description" width="7%" height="7%"/>
            <button className="sign_in_butt_inital_real" onClick={() => setDisplay("login")}>
              Sign In
            </button>
          </div>
          <img src={I_know_its_an_image_im_rushed}alt="Description" width="100%" height="100%" />
        </div>
      )}
    </div>
  );
}