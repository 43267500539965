import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from './Base_url';
import useToken from './TokenContext';

export default function Students() {
    const { classId } = useParams();
    const navigate = useNavigate();
    const { token } = useToken();
    const [classInfo, setClassInfo] = useState(null);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        // Fetch class info
        fetch(`${BASE_URL}/api/class/by_id?class_id=${classId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setClassInfo(data));

        // Fetch students
        fetch(`${BASE_URL}/api/class/students?class_id=${classId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setStudents(data));
    }, [classId, token]);

    return (
        <div className="students-container">
            {classInfo && (
                <div className="class-header">
                    <span className="class-icon">{classInfo.icon}</span>
                    <div className="class-info">
                        <h2 className="class-name">{classInfo.class_name}</h2>
                        <p className="teacher-name">{classInfo.teacher}</p>
                    </div>
                </div>
            )}

            <div className="students-list">
                {students.map((student, index) => (
                    <button
                        key={index}
                        className="student-item"
                        onClick={() => navigate(`/explore-profile/${student}`)}
                    >
                        {student}
                    </button>
                ))}
            </div>
        </div>
    );
}
