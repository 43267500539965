import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { BASE_URL } from "./Base_url.js";
import { previousTuesday } from "date-fns";

export const TokenContext = createContext();

export function TokenProvider({ children }) {
  const [token, setToken] = useState(null);
  const [profile, setProfile] = useState(null);
  const [classes, setClasses] = useState(null);
  const [do_today, setDoToday] = useState(null);
  const [pomos_this_week, setPomosThisWeek] = useState(null);
  const [statisticsData, setStatisticsData] = useState({
    days: null,
    weeks: null,
  });
  const [students_list, setStudents] = useState([]);

  const refreshToken = useCallback(async () => {
    const refreshTokenValue = localStorage.getItem("refreshToken");
    console.log("Refreshing token...");

    if (!refreshTokenValue) {
      console.error("Refresh token not found");
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}/token/refresh?refresh_token=${refreshTokenValue}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
          },
        }
      );

      const data = await response.json();

      if (data.access_token) {
        setToken(data.access_token);
        localStorage.setItem("accessToken", data.access_token);
        console.log("Token refreshed successfully");
        return;
      } else {
        console.error("Failed to refresh token");
        // Handle token refresh failure (e.g., redirect to login)
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  }, []);

  const getToken = useCallback(async () => {
    if (token) {
      return token;
    }

    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      setToken(storedToken);
      return storedToken;
    }

    await refreshToken();
    return token;
  }, [token, refreshToken]);

  const updateStudents = useCallback((studentData, remove) => {
    if (remove) {
      setStudents((prevStudent) =>
        prevStudent.filter((c) => c.username !== studentData.username)
      );
      console.log("FRAXIOM");
    } else {
      setStudents((prevStudent) => [...prevStudent, studentData]);
      console.log("FROM BERKLEY COLLEGE POLICE");
    }
  });

  const updateCurrentClasses = useCallback((classData, remove) => {
    if (remove) {
      setClasses((prevClasses) =>
        prevClasses.filter((c) => c.class_name !== classData.class_name)
      );
    } else {
      setClasses((prevClasses) => [...prevClasses, classData]);
      console.log("HAR HAR HAR HAR HAR");
    }
  }, []);

  const updateDoToday = useCallback((updatedTask, remove) => {
    if (remove) {
      setDoToday((do_today) =>
        do_today.filter((task) => task.task_id !== updatedTask.task_id)
      );
      console.log("EVERYONE IN IT");
    } else {
      setDoToday((do_today) => [...do_today, updatedTask]);
      console.log("FUCK THIS TOWN AND LKE ");
    }
  }, []);

  const updatePomosThisWeek = useCallback(() => {
    setPomosThisWeek((pomos_this_week) => pomos_this_week + 1);
  }, []);

  const refreshStatistics = useCallback(async () => {
    if (profile && profile[0] && token) {
      try {
        const [daysResponse, weeksResponse] = await Promise.all([
          fetch(`${BASE_URL}/api/report/days?username=${profile[0].username}`, {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(
            `${BASE_URL}/api/report/weeks?username=${profile[0].username}`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        ]);

        if (daysResponse.status === 401 || weeksResponse.status === 401) {
          await refreshToken();
          return await refreshStatistics();
        }

        const [daysData, weeksData] = await Promise.all([
          daysResponse.json(),
          weeksResponse.json(),
        ]);

        setStatisticsData({ days: daysData, weeks: weeksData });
      } catch (error) {
        console.error("Error refreshing statistics:", error);
      }
    }
  }, [profile, token, refreshToken]);

  const handleTaskCompletion = useCallback(
    async (taskId, status) => {
      if (!profile || !profile[0]) return;

      // Immediately update local state
      if (status === "done") {
        setDoToday((prev) => prev.filter((task) => task.task_id !== taskId));
      }

      try {
        const response = await fetch(
          `${BASE_URL}/api/task/${taskId}/status?username=${profile[0].username}&status=${status}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          await refreshToken();
          return await handleTaskCompletion(taskId, status);
        }

        if (response.ok) {
          // Update done status and done_date if task is marked as done
          if (status === "done") {
            await Promise.all([
              fetch(
                `${BASE_URL}/api/task/${taskId}/done?username=${profile[0].username}&done=true`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
              fetch(
                `${BASE_URL}/api/task/${taskId}/done_date?username=${profile[0].username}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);
          }

          // Refresh statistics immediately
          await refreshStatistics();
        }
      } catch (error) {
        console.error("Error updating task:", error);
      }
    },
    [profile, token, refreshToken, refreshStatistics]
  );

  const handlePomodoroCompletion = useCallback(
    async (pomodoroData) => {
      if (!profile || !profile[0]) {
        console.error("Profile not loaded");
        return;
      }

      try {
        const response = await fetch(`${BASE_URL}/api/pomodoro`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username: profile[0].username,
            ...pomodoroData,
          }),
        });

        if (response.status === 401) {
          await refreshToken();
          return await handlePomodoroCompletion(pomodoroData);
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Update all necessary statistics
        await Promise.all([updatePomosThisWeek(), refreshStatistics()]);

        return await response.json();
      } catch (error) {
        console.error("Error completing pomodoro:", error);
        throw error;
      }
    },
    [profile, token, refreshToken, updatePomosThisWeek, refreshStatistics]
  );

  useEffect(() => {
    const fetchProfile = async () => {
      const token = await getToken();
      if (!token) {
        return;
      }
      try {
        const response = await fetch(`${BASE_URL}/users/me/profile`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          try {
            await refreshToken();

            const response_2 = await fetch(`${BASE_URL}/users/me/profile`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const responseBody = await response_2.json();

            setProfile(responseBody);
  
            setStudents(responseBody[0].students);
            return responseBody;
          } catch (error) {
            // Handle token refresh failure
            throw error;
          }
        } else if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responseBody = await response.json();
        setProfile(responseBody);

        setStudents(responseBody[0].students);
        return responseBody;
      } catch (error) {
        console.error(error);
      }
    };
    fetchProfile();
  }, [refreshToken, getToken, token]);

  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(`${BASE_URL}/api/class?username=${profile[0].username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((fetchedData) => setClasses(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);

  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(`${BASE_URL}/api/tasks/do_today?username=${profile[0].username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((fetchedData) => setDoToday(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);

  useEffect(() => {
    if (profile && profile.length > 0) {
      fetch(
        `${BASE_URL}/api/pomodoro/done_this_week?username=${profile[0].username}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((fetchedData) => setPomosThisWeek(fetchedData))
        .catch((error) => console.error("Error:", error));
    }
  }, [profile]);

  useEffect(() => {
    if (profile && profile[0] && token) {
      refreshStatistics();
    }
  }, [profile, token, refreshStatistics]);

  return (
    <TokenContext.Provider
      value={{
        token,
        getToken,
        setToken,
        refreshToken,
        profile,
        classes,
        updateCurrentClasses,
        do_today,
        updateDoToday,
        pomos_this_week,
        updatePomosThisWeek,
        statisticsData,
        refreshStatistics,
        handleTaskCompletion,
        handlePomodoroCompletion,
        students_list,
        updateStudents,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
}

export default function useToken() {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }

  return context;
}
