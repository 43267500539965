import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from './Base_url';
import useToken from './TokenContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUserMinus } from '@fortawesome/free-solid-svg-icons';

export default function ExploreProfile() {
    const { username } = useParams();
    const { token, profile } = useToken();
    const [userProfile, setUserProfile] = useState(null);
    const [follows, setFollows] = useState(false);
    const [classes, setClasses] = useState([]);
    const [doToday, setDoToday] = useState([]);
    const [pomosThisWeek, setPomosThisWeek] = useState(0);

    useEffect(() => {
        // Fetch profile picture
        fetch(`${BASE_URL}/api/user/profile_picture_url/funny?username=${username}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setUserProfile(data));

        // Fetch classes
        fetch(`${BASE_URL}/api/class?username=${username}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setClasses(data));

        // Fetch today's tasks
        fetch(`${BASE_URL}/api/tasks/do_today?username=${username}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setDoToday(data));

        // Fetch pomodoros
        fetch(`${BASE_URL}/api/pomodoro/done_this_week?username=${username}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setPomosThisWeek(data));

        // Check if following
        fetch(`${BASE_URL}/api/follow/${username}/check?username=${profile[0].username}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => res.json())
            .then(data => setFollows(data));
    }, [username, token, profile]);

    const handleFollow = async () => {
        if (follows) {
            // Unfollow
            await fetch(`${BASE_URL}/api/follow/${username}?username=${profile[0].username}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` }
            });
        } else {
            // Follow
            await fetch(`${BASE_URL}/api/follow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    username: profile[0].username,
                    followed_username: username,
                    following: true,
                    creation_date: new Date().toISOString()
                })
            });
        }
        setFollows(!follows);
    };

    return (
        <div className="profile-container">
            {userProfile && (
                <>
                    <div className="profile-header">
                        <img className="profile-pic" src={userProfile} alt="Profile" />
                        <div className="profile-info">
                            <h2 className="username">{username}</h2>
                            <p className="pomodoros">Pomodoros this week: {pomosThisWeek}</p>
                            <button className="follow-button" onClick={handleFollow}>
                                <FontAwesomeIcon icon={follows ? faUserMinus : faUserPlus} />
                                {follows ? ' Unfollow' : ' Follow'}
                            </button>
                        </div>
                    </div>

                    <div className="today-tasks">
                        <h3>Today's Tasks</h3>
                        {doToday && doToday.length > 0 ? (
                            doToday.map((task, index) => (
                                <p key={index} className="task-item">{task.title}</p>
                            ))
                        ) : (
                            <p>No tasks for today</p>
                        )}
                    </div>

                    <div className="classes-grid">
                        {classes.map(classItem => (
                            <div key={classItem.class_id} className="class-item">
                                <div className="class-card">
                                    <span className="class-icon">{classItem.icon}</span>
                                    <p className="class-name">{classItem.class_name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
