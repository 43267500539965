import React, { useState, useEffect } from 'react';
import { BASE_URL } from './Base_url';
import useToken from './TokenContext';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function AddClass() {
    const { token, refreshToken, profile, classes, updateCurrentClasses } = useToken();
    const [allClasses, setAllClasses] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [className, setClassName] = useState('');
    const [teacher, setTeacher] = useState('');
    const [icon, setIcon] = useState('');
    const navigate = useNavigate();

    // Update the sorting function
    const sortClasses = (allClasses) => {
        return [...allClasses].sort((a, b) => {
            const aAdded = classes?.some(c => c.class_name === a.class_name);
            const bAdded = classes?.some(c => c.class_name === b.class_name);
            if (aAdded && !bAdded) return -1;
            if (!aAdded && bAdded) return 1;
            return 0;
        });
    };

    // Fetch all available classes
    useEffect(() => {
        fetch(`${BASE_URL}/api/class/universal`, {
            method: "GET",
            headers: {
                "accept": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => setAllClasses(data))
            .catch((error) => console.error("Error:", error));
    }, [classes]);

    // Update the createNewClass function
    const createNewClass = async () => {
        if (!className || !icon || !teacher) {
            alert('Please fill in all fields');
            return;
        }

        const classObject = {
            username: profile[0].username,
            class_id: "ha",
            class_name: className,
            teacher: teacher,
            icon: icon,
            in_session: true
        };

        const showClassObject = {
            class_name: className,
            teacher: teacher,
            icon: icon,
        };

        let jsonData;
        try {
            jsonData = JSON.stringify(classObject);
        } catch (error) {
            console.error('Error creating JSON from data:', error);
            return null;
        }

        try {
            const response = await fetch(`${BASE_URL}/api/class?username=${profile[0].username}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: jsonData
            });

            if (response.ok) {
                // Add the new class to both classes and allClasses
                updateCurrentClasses(classObject, false);
                setAllClasses(prev => [showClassObject, ...prev]); // Add new class to front of list
                setModalOpen(false);
                setClassName('');
                setTeacher('');
                setIcon('');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const toggleClass = async (classItem, isAdded) => {
        if (isAdded) {
            // Remove class
            try {
                await fetch(`${BASE_URL}/api/class/delete?username=${profile[0].username}&class_id=${classItem.class_id}`, {
                    method: "DELETE",
                    headers: {
                        'accept': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                updateCurrentClasses(classItem, true);
            } catch (error) {
                console.error(error);
            }
        } else {
            // Add existing class
            const classObject = {
                username: profile[0].username,
                class_id: classItem.class_id,
                class_name: classItem.class_name,
                teacher: classItem.teacher,
                icon: classItem.icon,
                in_session: true
            };

            try {
                const response = await fetch(`${BASE_URL}/api/class?username=${profile[0].username}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(classObject)
                });

                if (response.ok) {
                    updateCurrentClasses(classObject, false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="add_task_new_container addclass-page">
            {/* Back Button */}
            <button 
                className="back-button"
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faArrowLeft} size="2x" color="ivory" />
            </button>

            {/* Create New Class Button */}
            <button className="add_task_container" onClick={() => setModalOpen(true)}>
                <span>add class</span>
            </button>

            {/* Create New Class Modal */}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                className="modal"
            >
                <div className="actuall_add_task_container">
                    <TextField
                        className="assight_task_title_container"
                        label="Class Name"
                        value={className}
                        onChange={(e) => setClassName(e.target.value)}
                    />
                    <div className="input_row">
                        <TextField
                            className="add_class_input"
                            label="Teacher"
                            value={teacher}
                            onChange={(e) => setTeacher(e.target.value)}
                        />
                        <TextField
                            className="add_class_input"
                            label="Icon (emoji)"
                            value={icon}
                            onChange={(e) => setIcon(e.target.value)}
                        />
                    </div>
                    <button className="submit_routine_container" onClick={createNewClass}>
                        Submit
                    </button>
                </div>
            </Modal>

            {/* Existing Classes Grid */}
            <div className="classes-grid">
                {sortClasses(allClasses).map((classItem) => {
                    const isAdded = classes?.some(c => c.class_name === classItem.class_name);
                    return (
                        <div key={classItem.class_id} className="class-item">
                            <div 
                                className={`class-card ${isAdded ? 'added' : ''}`}
                                onClick={() => toggleClass(classItem, isAdded)}
                            >
                                <div className="toggle-icon-container">
                                    <FontAwesomeIcon 
                                        icon={isAdded ? faMinus : faPlus} 
                                        className="toggle-icon"
                                    />
                                </div>
                                <span className="class-icon">{classItem.icon}</span>
                                <p className="class-text">{classItem.class_name}</p>
                                <p className="teacher-name">{classItem.teacher}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
