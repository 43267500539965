import React, { useState, useEffect, useRef } from "react";
import { Modal, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { BASE_URL } from "./Base_url.js";
import useToken from "./TokenContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronRight,
  faCircleChevronLeft,
  faCircleXmark,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {format} from 'date-fns';
import { useAudio } from './AudiContext';
import makeAnimated from 'react-select/animated'


export default function TaskView() {
  const [display, setDisplay] = useState("kan-ban");
  const [taskList, setTasks] = useState([]);
  const { token, refreshToken, profile, updateDoToday, classes, handleTaskCompletion, students_list} =
    useToken();

  const [calendar_view, setCalendarView] = useState("do_date");
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState("todo");
  const [title, setTitle] = useState("");
  const [input_do_date, setInputDoDate] = useState(new Date());
  const [input_due_date, setInputDueDate] = useState(new Date());
  const [input_class_name, setInputClassName] = useState(null);
  const { playSound } = useAudio();
  const [theDoDate, setTheDoDate] = useState(new Date());
  const [theDueDate, setTheDueDate] = useState(new Date());
  const [selectedStudents, setSelectedStudents] = useState([]);


  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()) ;
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const animatedComponents = makeAnimated();

  const previousMonth = () => {
    setSelectedMonth(prev => prev - 1);
  }
  const nextMonth = () => {
    setSelectedMonth(prev => prev + 1);
  }

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleDragStart = (e, task) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(task));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, formattedDate) => {
    e.preventDefault();
    const taskData = JSON.parse(e.dataTransfer.getData('text/plain'));
    const updatedTasks = taskList.map((task) =>
      task.task_id === taskData.task_id ? { ...task, [calendar_view]: formattedDate } : task
    );
    setTasks(updatedTasks);
    if (calendar_view === "due_date"){

     changeDueDate(taskData.task_id, formattedDate);
    }
    else{ 
      const today = new Date();
      const today_formatted = format(today, 'yyyy-MM-dd');
      if(formattedDate === today_formatted){
        updateDoToday(taskData, false);
      } else if (taskData.do_date === today_formatted){
        updateDoToday(taskData, true);
      }
      changeDoDate(taskData.task_id, formattedDate);
    }
  };



  const changeDoDate = async (task_id, do_date) => {
 
    const response = await fetch(
      `${BASE_URL}/api/task/do_date?username=${profile[0].username}&task_id=${task_id}&do_date=${do_date}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );  

    if (response.status === 401) {
      try {
        await refreshToken(); 
        
      } catch (error) {
        
          // Handle token refresh failure
          throw error;
      }
    }

   

    };

    const changeDueDate = async (task_id, due_date) => {
 
        const response = await fetch(
          `${BASE_URL}/api/task/due_date?username=${profile[0].username}&task_id=${task_id}&due_date=${due_date}`,
          {
            method: "PUT",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );  
    
        if (response.status === 401) {
          try {
            await refreshToken(); 
            
          } catch (error) {
            
              // Handle token refresh failure
              throw error;
          }
        }
    
        };




    const generateID = () => {
      return '_' + Math.random().toString(36).substr(2, 9);
    };







  const renderCalendar = (type) => {
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
    const startDay = firstDayOfMonth === 0 ? 7 : firstDayOfMonth; // Adjust Sunday from 0 to 7
    const prevMonthDays = getDaysInMonth(selectedMonth - 1, selectedYear);
    const endDay = new Date(selectedYear, selectedMonth + 1, 0).getDay();

    const calendarRows = [];
    let calendarDays = [];

    // Render cells for days from the previous month
    for (let i = startDay - 1; i > 0; i--) {
      const prevMonthDate = new Date(selectedYear, selectedMonth - 1, prevMonthDays - i + 1);
      const formattedDate = format(prevMonthDate, 'yyyy-MM-dd');
      calendarDays.push(
        <div key={`prev-month-${prevMonthDays - i + 1}`} className="day_not_month"
             onDragOver={(e) => handleDragOver(e)}
             onDrop={(e) => handleDrop(e, formattedDate)}>
          <div className="day_number">{prevMonthDays - i + 1}</div>
          {renderTasksForDate(formattedDate, type)}
        </div>
      );
    }

    // Render cells for each day of the current month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(selectedYear, selectedMonth, day);
      const formattedDate = format(currentDate, 'yyyy-MM-dd');

      calendarDays.push(
        <div key={`day-${day}`} className="day" id={`day-${day}`}
             onDragOver={(e) => handleDragOver(e)}
             onDrop={(e) => handleDrop(e, formattedDate)}>
          <div className="day_number">{day}</div>
          {renderTasksForDate(formattedDate, type)}
        </div>
      );

      // If we've reached the end of a week, start a new row
      if ((startDay + day - 1) % 7 === 0) {
        calendarRows.push(
          <div key={`row-${calendarRows.length}`} className="calendar_container">
            {calendarDays}
          </div>
        );
        calendarDays = [];
      }
    }

    // Fill the remaining cells in the last row with days from the next month
    if (endDay !== 0) {
      const remainingDays = 7 - endDay;
      for (let i = 1; i <= remainingDays; i++) {
        const nextMonthDate = new Date(selectedYear, selectedMonth + 1, i);
        const formattedDate = format(nextMonthDate, 'yyyy-MM-dd');
        calendarDays.push(
          <div key={`next-month-${i}`} className="day_not_month"
               onDragOver={(e) => handleDragOver(e)}
               onDrop={(e) => handleDrop(e, formattedDate)}>
            <div className="day_number">{i}</div>
            {renderTasksForDate(formattedDate, type)}
          </div>
        );
      }
    }

    // Add the last row to the calendarRows array
    if (calendarDays.length > 0) {
      calendarRows.push(
        <div key={`row-${calendarRows.length}`} className="calendar_container">
          {calendarDays}
        </div>
      );
    }

    return calendarRows;
  };

  // Helper function to check if a string starts with an emoji
  const startsWithEmoji = (str) => {
    const emojiRegex = /^[\u{1F300}-\u{1F9FF}]|[\u{2600}-\u{26FF}]/u;
    return emojiRegex.test(str);
  };

  // Helper function to extract emoji from class name
  const getClassIcon = (className) => {
    if (!className || className === 'other' || className === "string") return '';
    const emoji = className.split(' ')[0];
    return emoji;
  };

  // Helper function to render tasks for a specific date
  const renderTasksForDate = (date, type) => {
    return taskList
      .filter((task) => task[type] === date)
      .map((task) => (
        <div key={task.task_id} 
             className={`calendar_task_container_${task.status}`}
             draggable="true"
             onDragStart={(e) => handleDragStart(e, task)}>
          {task.title} {getClassIcon(task.class_name)}
        </div>
      ));
  };



  const changeStatus = async (task_id, status) => {
    const response = await fetch(
      `${BASE_URL}/api/task/${task_id}/status?username=${profile[0].username}&status=${status}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }
  
    if (response.ok) {
      // Find the task that was updated
      const task = taskList.find((task) => task.task_id === task_id);
  
      // Update the task's status
      const updatedTask = { ...task, status };
  
      if (status === "done") {
        updatedTask.done = true;
        changeDoneDate(task_id);
      }
  
      // Update the state
      setTasks((tasks) => tasks.map((task) => task.task_id === task_id ? updatedTask : task));
    }
  };

  const deleteTask = async (task_id) => {
    const response = await fetch(
      `${BASE_URL}/api/task/${task_id}?username=${profile[0].username}`,
      {
        method: "DELETE",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (error) {
        // Handle token refresh failure
        throw error;
      }
    }

    if (response.ok) {
        setTasks((tasks) => tasks.filter((task) => task.task_id !== task_id));
    }
  };

  const onChangeDoDate = (date) => {

    const adjustedDate = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    setInputDoDate(formattedDate);
    setTheDoDate(date);
 
  };
  
  const onChangeDueDate = (date) => {
 
    const adjustedDate = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    setInputDueDate(formattedDate);
    setTheDueDate(date);

  };

  const isDateFormattedCorrectly = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  const changeDoneDate = async (task_id) => {
    const response = await fetch(
      `${BASE_URL}/api/task/${task_id}/done_date?username=${profile[0].username}`,
      {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };


  const upload_student_tasks = async () => {
    // Validate title
    if(title === "" || title === null){
      return;
    }

    // Format dates
    let lets_do_date = input_do_date;
    let lets_due_date = input_due_date;
  
    if (!isDateFormattedCorrectly(input_do_date)) {
      const adjustedDate = new Date(input_do_date.setMinutes(input_do_date.getMinutes() - input_do_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDoDate(formattedDate);
      lets_do_date = formattedDate;
    }
    
    if (!isDateFormattedCorrectly(input_due_date)) {
      const adjustedDate = new Date(input_due_date.setMinutes(input_due_date.getMinutes() - input_due_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDueDate(formattedDate);
      lets_due_date = formattedDate;
    }

    // Get status
    let stat = status?.value || "todo";

    // Extract student usernames
    const student_usernames = selectedStudents.map(student => student.value);

    // Create the request body
    const requestBody = {
      task: {
        username: profile[0].username,
        task_id: generateID(),
        title: title,
        class_name: input_class_name?.label || 'other',
        due_date: lets_due_date,
        do_date: lets_do_date,
        status: stat,
        done_date: "",
        done: false,
      },
      student_usernames: student_usernames
    };

    try {
      const response = await fetch(`${BASE_URL}/api/task/assign`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        await refreshToken();
        // You might want to retry the request here
      }

      const responseBody = await response.json();
      if (response.status === 200) {
        // Add only the teacher's task to the local state
        setTasks((tasks) => [requestBody.task, ...tasks]);
      }
    } catch (error) {
      console.error("Error assigning tasks:", error);
    }

    // Reset form
    setVisible(false);
    setTitle("");
    setStatus({ value: "todo", label: "Todo" });
  };



  const upload_task = async () => {
    // Create a new FormData instance

    if(title === "" || title === null){
      return;
    }

    let lets_do_date = input_do_date;
    let lets_due_date = input_due_date;
  

    if (!isDateFormattedCorrectly(input_do_date)) {
      console.log("do_date is not formatted correctly");
      const adjustedDate = new Date(input_do_date.setMinutes(input_do_date.getMinutes() - input_do_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDoDate(formattedDate);
      lets_do_date = formattedDate;
      
    }
    if (!isDateFormattedCorrectly(input_due_date)) {
      console.log("due_date is not formatted correctly");
      const adjustedDate = new Date(input_due_date.setMinutes(input_due_date.getMinutes() - input_due_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDueDate(formattedDate);
      lets_due_date = formattedDate;
    }

    let stat = "todo";

    if (status.value != null){
      stat = status.value;
    }
   
    

    

    // Create the wotd object
    const taskObject = {
      username: profile[0].username,
      task_id: generateID(),
      title: title,
      class_name: input_class_name?.label || 'other',  // Default to "other" without emoji
      due_date: lets_due_date,
      do_date: lets_do_date,
      status: stat,
      done_date: "",
      done: false,
    };

   

    // Append the wotd object to the FormData instance
    let jsonData;
    try {
      jsonData = JSON.stringify(taskObject);
    } catch (error) {
      console.error("Error creating JSON from data:", error);
      return null;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/task`, {
        method: "POST",
        body: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

    

      if (response.status === 401) {
        // Unauthorized
        await refreshToken();
        // Retry the request
      }

      const responseBody = await response.json();
      if (response.status === 200) {
       
        // Success
        setTasks((tasks) => [responseBody, ...tasks]); // Extract data from the response
      }
    } catch (error) {
      console.error(error);
    }
    setVisible(false);

    setTitle("");
    setStatus({ value: "todo", label: "Todo" });

  };



  useEffect(() => {
    const fetchTasks = async () => {
      if (profile && profile[0]) {
        fetch(`${BASE_URL}/api/task?username=${profile[0].username}`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((fetchedData) => setTasks(fetchedData))
          .catch((error) => console.error("Error:", error));
      }
    };

    fetchTasks();
  }, [profile]);

  //THIS IS GOING TO BE FOR THE DO AND DUE DATES
  



  const handleKanbanDragStart = (e, task) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(task));
  };

  const handleKanbanDragOver = (e) => {
    e.preventDefault();
  };

  const handleKanbanDrop = async (e, newStatus) => {
    e.preventDefault();
    const task = JSON.parse(e.dataTransfer.getData('text/plain'));
    
    if (task.status !== newStatus) {
      // Immediately update local state
      setTasks(prevTasks => 
        prevTasks.map(t => 
          t.task_id === task.task_id 
            ? { ...t, status: newStatus } 
            : t
        )
      );

      try {
        if (newStatus === "done") {
          playSound();
          await handleTaskCompletion(task.task_id, newStatus);
        } else {
          await changeStatus(task.task_id, newStatus);
        }
      } catch (error) {
        console.error("Error updating task status:", error);
        // Revert local state if API call fails
        setTasks(prevTasks => 
          prevTasks.map(t => 
            t.task_id === task.task_id 
              ? { ...t, status: task.status } 
              : t
          )
        );
      }
    }
  };

  // Near the top of your component, create the class options array
  const classOptions = classes ? [
    ...classes.map(c => ({
      value: c.class_name,
      label: `${c.icon} ${c.class_name}`
    })),
    { value: 'other', label: 'other' }  // Removed emoji from 'other'
  ] : [{ value: 'other', label: 'other' }];

  // Then update the Select component
  <Select
    className="select_container class_select"
    placeholder="Class"  // This will show when nothing is selected
    value={input_class_name}
    onChange={(selectedOption) => setInputClassName(selectedOption)}
    options={classOptions}
    isClearable={true}  // Allows clearing the selection
    defaultValue={null}  // Starts with no selection
  />

  const studentOptions = students_list ? [
    ...students_list.map(c => ({
      value: c.username,
      label: `${c.username}`
    })),
  ]: [{value: "test", label: "test"}];





  // In your task rendering function
  const renderTask = (task) => {
    // Extract the emoji from the class_name (it's the first character)
    const classIcon = task.class_name?.split(' ')[0] || '📝';
    return (
      <div className="task_header">
        {task.title} {classIcon}
      </div>
    );
  };


  // Add new state for editing
  const [isEditing, setIsEditing] = useState(false);
  const [editingTask, setEditingTask] = useState(null);

  // Modify the modal open handler
  const handleTaskClick = (task) => {
    setEditingTask(task);
    setTitle(task.title);
    setInputClassName({ value: task.class_name, label: task.class_name });
    setStatus({ value: task.status, label: task.status.charAt(0).toUpperCase() + task.status.slice(1) });
    if (task.assigned_students){
      setSelectedStudents(task.assigned_students.map(student => ({ value: student, label: student })));
    }
    
    // Fix timezone issues by setting time to noon
    const doDate = new Date(task.do_date + 'T12:00:00');
    const dueDate = new Date(task.due_date + 'T12:00:00');
    
    setInputDoDate(doDate);
    setInputDueDate(dueDate);
    setVisible(true);
    setIsEditing(true);
  };

  // Add update task function
  const updateTask = async () => {
    if (!title) return;

    try {
      // Format dates correctly for the API
     

      const formattedDoDate = format(input_do_date, 'yyyy-MM-dd');
      const formattedDueDate = format(input_due_date, 'yyyy-MM-dd');

      let lets_do_date = input_do_date;
      let lets_due_date = input_due_date;
  
      if (!isDateFormattedCorrectly(input_do_date)) {
      const adjustedDate = new Date(input_do_date.setMinutes(input_do_date.getMinutes() - input_do_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDoDate(formattedDate);
      lets_do_date = formattedDate;
    }
    
    if (!isDateFormattedCorrectly(input_due_date)) {
      const adjustedDate = new Date(input_due_date.setMinutes(input_due_date.getMinutes() - input_due_date.getTimezoneOffset()));
      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
      setInputDueDate(formattedDate);
      lets_due_date = formattedDate;
    }

      // Update title
      await fetch(`${BASE_URL}/api/task/title?username=${editingTask.username}&task_id=${editingTask.task_id}&title=${title}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      // Update class name
      if (input_class_name?.label) {
        await fetch(`${BASE_URL}/api/task/class_name?username=${editingTask.username}&task_id=${editingTask.task_id}&class_name=${input_class_name.label}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },  
        });
      }

      // Update do date
      await changeDoDate(editingTask.task_id, lets_do_date);

      // Update due date
      await changeDueDate(editingTask.task_id, lets_due_date);

      // Update status
      await fetch(`${BASE_URL}/api/task/status_new?username=${editingTask.username}&task_id=${editingTask.task_id}&status=${status.value}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (profile && profile[0].role === "teacher") {
        await fetch(`${BASE_URL}/api/task/students?task_id=${editingTask.task_id}&username=${editingTask.username}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            student_usernames: selectedStudents.map(student => student.value)
          }),
        });
      }



      // Update local state with all changes
      const updatedTask = {
        ...editingTask,
        title: title,
        class_name: input_class_name?.label || editingTask.class_name,
        status: status.value,
        do_date: lets_do_date,
        due_date: lets_due_date,
      };

      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.task_id === editingTask.task_id ? updatedTask : task
        )
      );

      // Reset form
      setVisible(false);
      setIsEditing(false);
      setEditingTask(null);
      setTitle("");
      setStatus({ value: "todo", label: "Todo" });
      setInputClassName(null);
      
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };


  

  // Modify the modal submit button to handle both create and update
  const handleSubmit = () => {
    if (isEditing) {
      updateTask();
    } else {
      if (profile && profile[0].role === "teacher"){
        upload_student_tasks();
      } else {
        upload_task();
      }
    }
  };

  return (
    <div
      className="haha"
      style={{ flexGrow: 1, backgroundColor: "#2b2b2b", width: "100%" }}
    >
      <Modal open={visible} onClose={() => setVisible(false)} style = {{width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
        <div className="actuall_add_task_container">
          <TextField
            maxLength={24}
            label="Title"
            value={title}
            style={{ 
              width: "80%",  // Changed from 80% to 40%
              backgroundColor: "ivory", 
              color: "#2b2b2b" 
            }}
            onChange={(e) => setTitle(e.target.value)}
            className="assign_task_title_container"
          />
          <div className="task_details_container">
            <div className="task_details_row">
              <div className="date_picker_container_titles">DO date</div>
              <div className="date_picker_container_titles">Due date</div>
              
            </div>
            <div className="task_details_row">
              <div className="date_picker_container">
              <DatePicker
                className="date_pucker"
                selected={theDoDate}
                onChange={onChangeDoDate}
          
              /></div>
             <div className="date_picker_container">
              <DatePicker
               className = "date_pucker"
               selected={theDueDate}
                onChange={onChangeDueDate}
    
              />
              </div>
            </div>
          
          <div className="task_details_row">
            <div>
          <Select
            className="select_container class_select"  // Added status_select class
            placeholder="Class"
            value={input_class_name}
            onChange={(selectedOption) => setInputClassName(selectedOption)}
            options={classOptions}
            isClearable = {true}
            defaultValue={null}
          />

          <Select
            className="select_container status_select"  // Added class_select class
            placeholder="Status"
            value={status}
            onChange={(selectedOption) => setStatus(selectedOption)}
            options={[
              { label: "To Do", value: "todo" },
              { label: "Doing", value: "doing" },
              { label: "Done", value: "done" },
            ]}
          />
          
          </div>
          <div>
          {profile && profile.length > 0 && profile[0].role === "teacher" && (
            
          <Select
            className="select_container class_select"  // Added status_select class
            placeholder="Students"
            value={selectedStudents}
            onChange={(selectedOption) => setSelectedStudents(selectedOption)}
            options={studentOptions}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            isClearable = {true}
            defaultValue={null}
          />)}
            <button className="sign_in_butt_task_new" onClick={() => handleSubmit()}>
            Submit
          </button></div>
          </div>
          </div>
        </div>
      </Modal>

      <div className="quercus">
        <button
          className={`kan_ban_headers`}
          style={{ opacity: display === "kan-ban" ? 1 : 0.5 }}
          onClick={() => setDisplay("kan-ban")}
        >
          Kanban
        </button>
        <button
          className={`calendar_header`}
          style={{ opacity: display === "calendar" ? 1 : 0.5 }}
          onClick={() => setDisplay("calendar")}
        >
          Calendar
        </button>
      </div>

      {display === "kan-ban" ? (
        <div className="quercus">
          <div className="taskview-add-container">
            <button className="add_task_container" onClick={() => setVisible(true)}>
              Add Task
            </button>
          </div>
          <div className="task_and_routines_container">
            {/* TODO Column */}
            <div 
              className="board_containers"
              onDragOver={handleKanbanDragOver}
              onDrop={(e) => handleKanbanDrop(e, "todo")}
            >
              <div className="todo_container">To Do</div>
              {taskList && taskList.length > 0 ? (
                taskList
                  .filter((item) => item.status === "todo")
                  .map((item, index) => (
                    <div 
                      key={index.toString()} 
                      className="new_task"
                      draggable="true"
                      onDragStart={(e) => handleKanbanDragStart(e, item)}
                      onClick={() => handleTaskClick(item)}
                    >
                      <div className="task_header">
                        <button className="move-button" onClick={(e) => {
                          e.stopPropagation();  // Prevent task click when clicking delete
                          deleteTask(item.task_id);
                        }}>
                          <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "ivory" }} />
                        </button>
                        <span className="task-text">
                          {item.title} {getClassIcon(item.class_name)}
                        </span>
                      </div>
                      {/* Removed the right arrow */}
                    </div>
                  ))
              ) : (
                <div className="no_tasks"></div>
              )}
            </div>

            {/* DOING Column */}
            <div 
              className="board_containers"
              onDragOver={handleKanbanDragOver}
              onDrop={(e) => handleKanbanDrop(e, "doing")}
            >
              <div className="doing_container">Doing</div>
              {taskList && taskList.length > 0 ? (
                taskList
                  .filter((item) => item.status === "doing")
                  .map((item, index) => (
                    <div 
                      key={index.toString()} 
                      className="new_task"
                      draggable="true"
                      onDragStart={(e) => handleKanbanDragStart(e, item)}
                      onClick={() => handleTaskClick(item)}
                    >
                      <div className="task_header">
                      <button
                          className="move-button"
                          onClick={(e) => {
                            changeStatus(item.task_id, "done");
                            playSound();
                            e.stopPropagation();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSquareCheck}
                            size="2xl"
                            style={{ color: "ivory" }}
                          />
                        </button>
                        <span className="task-text">
                          {item.title} {getClassIcon(item.class_name)}
                        </span>
                      </div>
                      {/* Removed the left arrow */}
                    </div>
                  ))
              ) : (
                <div className="no-tasks"></div>
              )}
            </div>

            {/* DONE Column */}
            <div 
              className="board_containers"
              onDragOver={handleKanbanDragOver}
              onDrop={(e) => handleKanbanDrop(e, "done")}
            >
              <div className="done_container">Done</div>
              {taskList && taskList.length > 0 ? (
                taskList
                  .filter((item) => item.status === "done")
                  .sort((a, b) => {
                    // Convert dates to timestamps for comparison
                    const dateA = new Date(a.do_date + 'T12:00:00');
                    const dateB = new Date(b.do_date + 'T12:00:00');
                    return dateB - dateA; // Sort descending (newest first)
                  })
                  .slice(0, 10) // Take only the first 10 items
                  .map((item, index) => (
                    <div 
                      key={index.toString()} 
                      className="new_task"
                      draggable="true"
                      onDragStart={(e) => handleKanbanDragStart(e, item)}
                      onClick={() => handleTaskClick(item)}
                    >
                      <div className="task_header_done">
                        <span className="task-text">
                          {item.title} {getClassIcon(item.class_name)}
                        </span>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-tasks"></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="quercus">
         
            
          <div className="quercus">
          <button
          className={`do_date_header`}
          style={{ opacity: calendar_view === "do_date" ? 1 : 0.5 }}
          onClick={() => setCalendarView("do_date")}
        >
            DO date
        </button>
        <button
          className={`due_date_header`}
          style={{ opacity: calendar_view === "due_date" ? 1 : 0.5 }}
          onClick={() => setCalendarView("due_date")}
        >
          DUE date
        </button>
          </div>

          {calendar_view === "due_date" ? (
            <div className="quercus">
            <div className="construct">
              <div className="calendar_container">
                <div className="month_name">{monthNames[selectedMonth]}</div>
                <button className="month_button" onClick={previousMonth}>{"<"}</button>
                <button className="month_button" onClick={nextMonth}>{">"}</button>
              </div>
              <div className="calendar_container">
                <div className="day_container">
                  <div className="day_titles">Mon</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Tues</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Wed</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Thurs</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Fri</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sat</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sun</div>
                </div>
              </div>
              {renderCalendar("due_date")}
            </div>
          </div>
        
          ) : (
            <div className="quercus">
            <div className="construct">
              <div className="calendar_container">
                <div className="month_name">{monthNames[selectedMonth]}</div>
                <button className="month_button" onClick={previousMonth}>{"<"}</button>
                <button className="month_button" onClick={nextMonth}>{">"}</button>
              </div>
              <div className="calendar_container" >
                <div className="day_container">
                  <div className="day_titles">Mon</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Tues</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Wed</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Thurs</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Fri</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sat</div>
                </div>
                <div className="day_container">
                  <div className="day_titles">Sun</div>
                </div>
              </div>
              {renderCalendar("do_date")}
            </div>
          </div>
          )}
       
        </div>
      )}
    </div>
  );
}

